<div class="company-select-container">
  <input type="text" [style.display]="selectedCompany ? 'none' : 'block'" class="liquid-form-input select-company-global-input" (keyup)="searchCompanies($event)" (keydown.enter)="setCompany(possibleCompanies[0])" [(ngModel)]="searchField" placeholder="Please enter company name to set global company"/>
  <i class="fa fa-search search-company-icon" *ngIf="!selectedCompany"></i>
  <div class="companies-search-container">
    <p class="companies-search-item" *ngFor="let company of possibleCompanies | slice: 0 : 10" (click)="setCompany(company)">{{ company.name }}</p>
  </div>
  <div class="selected-company-value" [style.display]="selectedCompany ? 'flex': 'none'">
    <p class="selected-company-value-text">Selected company: {{selectedCompany ? selectedCompany.name : '' }}</p>
    <button class="liquid-primary-button selected-company-value-button" (click)="refreshCompany()">Refresh</button>
  </div>
</div>
