import { Injectable } from '@angular/core';
import * as XLSX from 'xlsx';

@Injectable({
  providedIn: 'root'
})
export class FileHelperService {

  constructor() { }

  imageFiles = {
    upload: './assets/images/file-input-image.png',
    excel: './assets/images/file-input-excel.png',
    pdf: './assets/images/file-input-pdf.png',
    generic: './assets/images/invoice-icon.png',
  };
  getFileIcon(fileName: string): string {
    const extension = fileName.substring(fileName.lastIndexOf('.') + 1, fileName.length);
    
    if (['csv','xls','xlsx'].includes(extension))
      return this.imageFiles.excel;
    else if ('pdf' === extension)
      return this.imageFiles.pdf;
    else
      return this.imageFiles.generic;
  }

  formatBytes(bytes: number, decimals: number = 2) {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  async parseFile(file: Blob): Promise<any[]> {
    return new Promise((resolve, reject) => {
      const reader: FileReader = new FileReader();

      reader.onload = (e: any) => {
        /* create workbook */
        const binarystr: string = e.target.result;
        const wb: XLSX.WorkBook = XLSX.read(binarystr, { type: 'binary' });
  
        /* selected the first sheet */
        const wsname: string = wb.SheetNames[0];
        const ws: XLSX.WorkSheet = wb.Sheets[wsname];
  
        /* save data */
        const data: any[] = XLSX.utils.sheet_to_json(ws); // to get 2d array pass 2nd parameter as object {header: 1}
        //console.log(data); // Data will be logged in array format containing objects
  
        resolve(data);
      };

      reader.onerror = (e: any) => {
        reject('Error while reading the file');
      };

      reader.readAsBinaryString(file);
    })
  }
}
