import { AfterViewInit, Component, ElementRef, OnDestroy, ViewChild } from '@angular/core';

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss']
})
export class CarouselComponent implements AfterViewInit, OnDestroy {

  @ViewChild("container") private container: ElementRef;
  private carousel: any;
  private resizeObserver: ResizeObserver;
  private mutationObserver: MutationObserver;

  constructor() { }
  ngAfterViewInit(): void {
    this.carousel = this.container?.nativeElement;
    this.checkButtonsVisibility();

    this.resizeObserver = new ResizeObserver(() => this.checkButtonsVisibility());
    this.mutationObserver = new MutationObserver(() => this.checkButtonsVisibility());

    this.resizeObserver.observe(this.carousel);
    this.mutationObserver.observe(this.carousel, { childList: true });
  }
  
  ngOnDestroy(): void {
    this.resizeObserver.disconnect();
    this.mutationObserver.disconnect();
  }

  onScroll(event){
    this.carousel.classList.remove('start');
    this.carousel.classList.remove('end');
    if (this.carousel.scrollLeft === 0)
      this.carousel.classList.add('start');
    if (this.carousel.clientWidth + this.carousel.scrollLeft === this.carousel.scrollWidth)
      this.carousel.classList.add('end');
  }

  scroll(direction: 1 | -1 = 1){
    const width = this.carousel.childNodes[0]
      ? parseInt(window.getComputedStyle(this.carousel.childNodes[0]).getPropertyValue('min-width')) || 300
      : 300;
    this.carousel.scrollTo(this.carousel.scrollLeft + width * direction, 0);
  }

  private checkButtonsVisibility(){
    if (!this.carousel)
      return;
    if (this.carousel.clientWidth + this.carousel.scrollLeft === this.carousel.scrollWidth)
      this.carousel.classList.add('end');
    else
      this.carousel.classList.remove('end');
  }

}
