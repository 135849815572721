<div [style.display]="shown ? 'block' : 'none'" class="month-input-container" (clickOutside)="closeInput($event)">
  <div class="month-input-container-header">
    <i class="fa fa-angle-left month-input-header-icon" (click)="movePrevYear()"></i>
    <p class="month-input-year-label">{{ selectedYear }}</p>
    <i class="fa fa-angle-right month-input-header-icon" (click)="moveNextYear()"></i>
  </div>
  <div class="month-input-container-body">
    <div class="month-input-body-item {{ (selectedMonth == 1 && currentYear == selectedYear) ? 'month-input-body-item-selected' : ''}}" (click)="setMonth(1)">
      <p class="month-input-item-value">January</p>
    </div>
    <div class="month-input-body-item {{ (selectedMonth == 2 && currentYear == selectedYear) ? 'month-input-body-item-selected' : ''}}" (click)="setMonth(2)">
      <p class="month-input-item-value">February</p>
    </div>
    <div class="month-input-body-item {{ (selectedMonth == 3 && currentYear == selectedYear) ? 'month-input-body-item-selected' : ''}}" (click)="setMonth(3)">
      <p class="month-input-item-value">March</p>
    </div>
    <div class="month-input-body-item {{ (selectedMonth == 4 && currentYear == selectedYear) ? 'month-input-body-item-selected' : ''}}" (click)="setMonth(4)">
      <p class="month-input-item-value">April</p>
    </div>
    <div class="month-input-body-item {{ (selectedMonth == 5 && currentYear == selectedYear) ? 'month-input-body-item-selected' : ''}}" (click)="setMonth(5)">
      <p class="month-input-item-value">May</p>
    </div>
    <div class="month-input-body-item {{ (selectedMonth == 6 && currentYear == selectedYear) ? 'month-input-body-item-selected' : ''}}" (click)="setMonth(6)">
      <p class="month-input-item-value">June</p>
    </div>
    <div class="month-input-body-item {{ (selectedMonth == 7 && currentYear == selectedYear) ? 'month-input-body-item-selected' : ''}}" (click)="setMonth(7)">
      <p class="month-input-item-value">July</p>
    </div>
    <div class="month-input-body-item {{ (selectedMonth == 8 && currentYear == selectedYear) ? 'month-input-body-item-selected' : ''}}" (click)="setMonth(8)">
      <p class="month-input-item-value">August</p>
    </div>
    <div class="month-input-body-item {{ (selectedMonth == 9 && currentYear == selectedYear) ? 'month-input-body-item-selected' : ''}}" (click)="setMonth(9)">
      <p class="month-input-item-value">September</p>
    </div>
    <div class="month-input-body-item {{ (selectedMonth == 10 && currentYear == selectedYear) ? 'month-input-body-item-selected' : ''}}" (click)="setMonth(10)">
      <p class="month-input-item-value">October</p>
    </div>
    <div class="month-input-body-item {{ (selectedMonth == 11 && currentYear == selectedYear) ? 'month-input-body-item-selected' : ''}}" (click)="setMonth(11)">
      <p class="month-input-item-value">November</p>
    </div>
    <div class="month-input-body-item {{ (selectedMonth == 12 && currentYear == selectedYear) ? 'month-input-body-item-selected' : ''}}" (click)="setMonth(12)">
      <p class="month-input-item-value">December</p>
    </div>
  </div>
</div>
