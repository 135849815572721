<main>
  <div class="wrapper">
    <h2>Manage your payroll and invoice payments securely on LiquidNow Corporate Payment Portal</h2>
    <section>
      <img class="liquid-now-logo" src="./assets/images/liquid-now-logo.png" alt="LiquidNow">
      <h1>Sign Up</h1>
      <p>Fill up the application form using <img src="./assets/images/myinfo-biz-logo.png" alt="Myinfo business" title="Myinfo business"></p>
      <button class="myinfo" (click)="myInfoLogin()">
        Retrieve Myinfo business
        <br><small>with <img src="./assets/icons/singpass.svg" alt="Singpass" title="Singpass"></small>
      </button>
      <p>OR</p>
      <a class="primary" [routerLink]="'/self-onboarding'" [queryParams]="{partner, lead}">
        Apply through Online Application Form
      </a>
    </section>
  </div>
  <footer>
    <p>Powered by <img src="./assets/images/auth-footer-logo.png"/></p>
    <hr>
    <p>©{{year}} LiquidNow Corporate Payment Portal. All rights reserved.</p>
  </footer>
</main>
