import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType, Effect } from '@ngrx/effects';
import { of, EMPTY } from 'rxjs';
import { map, exhaustMap, catchError, switchMap, concatMap } from 'rxjs/operators';
import { InstructionService } from '../../shared/services/instruction/instruction.service';
import { InvoiceService } from '../../shared/services/invoice/invoice.service';
import { EnumNotificationsActions, getNotificationsSuccess, getNotifications, getInvoices } from '../actions/notifications.actions';
// import * as notificationsActions from '../actions';
import * as moment from "moment";
import 'moment-timezone';
import { deepClone } from 'src/app/app.module';

@Injectable()
export class NotificationsEffects {
  constructor(
    private instructionService: InstructionService,
    private invoiceService: InvoiceService,
    private actions$: Actions
  ) {
    var locale = window.navigator['userLanguage'] || window.navigator.language;
    moment.locale(locale);
    let date = new Date();
    this.timeZone = moment(date).utcOffset();
  }
  timeZone: number;
  
  getNotifications$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<getNotifications>(EnumNotificationsActions.getNotifications),
      switchMap((action) => this.instructionService.getInstructionsToApprove()),
      switchMap((eventHttp: any) => of(new getInvoices(eventHttp)))
    );
  });
  
  getInvoices$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<getInvoices>(EnumNotificationsActions.getInvoices),
      concatMap(action =>
        this.invoiceService.getInvoicesToApprove().pipe(
          map(request => {
            let list = [];
            request.instructionsToApprove.forEach(element => {
              list.push({
                ...deepClone(element),
                createdAt: moment(element.createdAt).add(this.timeZone, 'minute'),
              });
            });
            
            action.payload['instructionsToApprove'].forEach(element => {
              list.push({
                ...deepClone(element),
                createdAt: moment(element.createdAt).add(this.timeZone, 'minute'),
              });
            });
            return new getNotificationsSuccess(list);
          })
        )
      ));
    });
  
    // exhaustMap(action =>
    //   this.instructionService.getInstructionsToApprove().pipe(
    //     map((response: any) => {
    //       console.log('Effect works good', response);
    //       let notifications = [];
    //       response.instructionsToApprove.forEach(element => {
    //         notifications.push(element);
    //       });
    
    // response2.instructionsToApprove.forEach(element => {
    //   notifications.push(element);
    // });
    
    // notifications.forEach(element => {
    //    element.createdAt = moment(element.createdAt).add(this.timeZone, 'minute');
    // });
    
    // return of(new getNotificationsSuccess(notifications));
    // this.invoiceService.getInvoicesToApprove().pipe(
    //   map((response2: any) => {
    //     console.log('Effect works good 2', response);
    //     let notifications = [];
    //     response.instructionsToApprove.forEach(element => {
    //       notifications.push(element);
    //     });
    //
    //     response2.instructionsToApprove.forEach(element => {
    //       notifications.push(element);
    //     });
    //
    //     notifications.forEach(element => {
    //        element.createdAt = moment(element.createdAt).add(this.timeZone, 'minute');
    //     });
    //
    //     return of(new getNotificationsSuccess(notifications))
    //   }))
    //       }))
    //   )
    // );
  }
  