<header class="shadow">
  <nav class="wizard-nav page-wrapper">
    <a href="/payments" class="logo"><img src="./assets/images/liquid-now-logo.png" alt="Liquid Now"/></a>
    <app-wizard-steps class="wizard" [steps]="steps"></app-wizard-steps>
    <a (click)="cancel()" class="cancel-button">
      <img src="./assets/icons/cancel-icon.svg" alt="Cancel"/>
    </a>
  </nav>
  <hr>
  <nav class="wizard-nav page-wrapper">
    <button class="secondary" [class.hidden]="steps.current === 0"
      (click)="setStep(steps.current - 1)">Back</button>
    <div class="wizard-header">
      <h3>{{steps[steps.current].section.title}}</h3>
      <p>{{steps[steps.current].section.subtitle}}</p>
    </div>
    <button [disabled]="!steps[steps.current].dirty || !steps[steps.current].valid"
      (click)="setStep(steps.current + 1)">{{steps.current + 1 !== steps.length ? 'Next' : 'Submit'}}</button>
  </nav>
</header>