import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType, Effect } from '@ngrx/effects';
import { of, EMPTY } from 'rxjs';
import { map, exhaustMap, catchError, switchMap, concatMap } from 'rxjs/operators';
import { InstructionService } from '../../shared/services/instruction/instruction.service';
import { CompanyService } from '../../shared/services/company/company.service';
import { EnumCompaniesActions, getCompaniesSuccess, getCompanies } from '../actions/companies.actions';
// import * as notificationsActions from '../actions';
import * as moment from "moment";
import 'moment-timezone';

@Injectable()
export class CompaniesEffects {
    timeZone: number;

    @Effect() getCompanies$ =
    this.actions$.pipe(
      ofType<getCompanies>(EnumCompaniesActions.getCompanies),
      switchMap((action) => this.companyService.getCompanies()),
      switchMap((eventHttp: any) => of(new getCompaniesSuccess(eventHttp)))
    );

  constructor(
    private instructionService: InstructionService,
    private companyService: CompanyService,
    private actions$: Actions
  ) {

  }
}
