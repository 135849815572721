import { Component, OnInit, OnDestroy } from '@angular/core';
import { InstructionService } from '../../services/instruction/instruction.service';
import { CompanyService } from '../../services/company/company.service';
import { ReplaySubject, Subscription } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-company-select',
  templateUrl: './company-select.component.html',
  styleUrls: ['./company-select.component.scss']
})
export class CompanySelectComponent implements OnInit, OnDestroy {
  searchField: string = '';
  possibleCompanies = [];
  companies = [];
  selectedCompany: any;
  subscription: Subscription;
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  constructor(public instructionService: InstructionService, public companyService:CompanyService) {
    this.subscription = this.companyService.getSelectedCompanyMap().pipe(takeUntil(this.destroyed$)).subscribe(value => {
      if (value != 0){
        this.selectedCompany = this.companies.find(x => x.companyId == localStorage.getItem('liquid_payout_portal_companyId'));
      } else {
        this.selectedCompany = null;
      }
    });
  }

  ngOnInit(): void {
    this.companyService.getCompanies().subscribe(companies => {
      this.companies = companies.companies;
      if (localStorage.getItem('liquid_payout_portal_companyId')){
        this.selectedCompany = this.companies.find(x => x.companyId == localStorage.getItem('liquid_payout_portal_companyId'));
      }
    })
  }

  searchCompanies(event){
    this.possibleCompanies = [];
    if (event.target.value.length > 2){
      this.companies.forEach(element => {
        if (element.name.toLowerCase().includes(event.target.value.toLowerCase())){
          this.possibleCompanies.push(element);
        }
      });
    }
  }

  refreshCompany(){
    this.selectedCompany = null;
    localStorage.setItem('liquid_payout_portal_companyId', null);
    this.companyService.updateCompanySelection(0);
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  setCompany(company){
    // localStorage.setItem('liquid_payout_portal_companyId', company.companyId);
    // this.companyService.updateCompanySelection(1);
    // this.selectedCompany = company;
    // this.possibleCompanies = [];
    // this.searchField = '';
  }
}
