import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-liquid-modal',
  templateUrl: './liquid-modal.component.html',
  styleUrls: ['./liquid-modal.component.scss'],
})

export class LiquidModalComponent implements OnInit {
  @Input() isOpen: boolean = false;
  @Input() isLarge: boolean = false;
  @Input() width: string;
  @Input() modalTitle: string;
  @Input() message: string;
  @Input() confirmLabel: string;
  @Input() closeLabel: string;
  @Input() alwaysOpen: boolean = false;
  @Input() isDanger: boolean = false;
  @Input() isSuccess: boolean = false;
  @Input() isCloseSecondary: boolean = false;
  @Input() noButtons: boolean = false;
  @Input() hasDisabledField: boolean = false;
  @Input() disabledConfirmButton: boolean = false;
  @Output() confirm: EventEmitter<boolean> = new EventEmitter<boolean>();
  constructor() { }

  ngOnInit(): void { }
  handleOpen(){
    this.isOpen = true;
  }
  handleClose(){
    this.confirm.emit(false);
  }
  handleClickOutside(){
    if(this.alwaysOpen) return;
    this.confirm.emit(false);
  }
  handleConfirm(){
    this.confirm.emit(true);
  }
}
