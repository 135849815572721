import { EnumRolesActions } from '../actions/roles.actions';
import { RolesActions } from '../actions/roles.actions';
import { initialRolesState} from '../state/roles.state';

export function rolesReducer(
  state = initialRolesState,
  action: RolesActions
) {
  switch (action.type) {
    case EnumRolesActions.getRolesSuccess:
      return {
        ...state,
        roles: action.payload.data
      };
    case EnumRolesActions.getDefaultRolesSuccess:
      return {
        ...state,
        defaultRoles: action.payload.data
      };
    default:
      return state;
  }
}
