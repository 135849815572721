<div
  class="liquid-custom-input-container {{ customCssClass }}"
  [style.width]="length == 'max' ? '100%' : '280px'"
  (clickOutside)="closeDropdown()">
  <input
    [(ngModel)]="selectedValue"
    id="select-input-{{outerId || uuid}}"
    class="liquid-custom-input"
    [class.liquid-payout-input]="type == 'wide'"
    [class.custom-select-input-wide]="type == 'wide'"
    [class.input-readonly]="readonly"
    readonly
    [placeholder]="placeholder"
    (click)="toggleDropdown()"/>
  <img
    (click)="toggleDropdown()"
    src="../../../assets/icons/arrow-down.svg"
    class="liquid-custom-input-arrow"
    [class.status-filter-input-arrow]="type === 'wide' && length !== 'max'"
    [class.max-length-img]="length === 'max'" />
  <div
    *ngIf="dropdownOpened && searchBar"
    class="liquid-custom-input-search-section">
    <div class="liquid-custom-input-search-container">
      <input
        type="text"
        class="liquid-payout-input search-field-input-custom reference-id-input"
        [(ngModel)]="searchFieldValue"
        placeholder="Search..."/>
      <img class="search-field-icon-custom" src="../../../assets/icons/search-field-icon.svg"/>
    </div>
  </div>
  <div
    *ngIf="dropdownOpened"
    [style.max-height]="maxHeight ? maxHeight : (length == 'max' ? '300px' : '400px')"
    class="dropdown-list"
    [class.dropdown-list-wide]="type === 'wide'"
    [class.dropdown-list-without-search-bar]="searchBar === false"
    [class.dropdown-list-multiselect]="multiselect">
    <ng-container *ngFor="let item of list">
      <div
        *ngIf="filter(item)"
        class="dropdown-list-item"
        [class.dropdown-list-item-selected]="selectedValue === item[label]"
        (click)="selectOption(item, $event)">
        <img
          *ngIf="multiselect"
          [src]="'../../../assets/icons/checkbox-' + (item.selected ? '' : 'un') + 'checked.svg'"
          class="liquid-payout-checkbox"/>
        <p class="dropdown-list-item-text">{{item[label]}}</p>
      </div>
    </ng-container>
  </div>
</div>
