<label class="upload-box" [class.has-file]="file" for="file-input-{{uuid}}"
  ngFileDrop (uploadOutput)="onUpload($event)">
  <input id="file-input-{{uuid}}" type="file" [accept]="extensions"
    #fileInput ngFileSelect (uploadOutput)="onUpload($event)"/>
  <img class="file-upload-image" [src]="imageFile"/>
  <div *ngIf="!file">
    <p>Drop your file here, or
      <button class="link" (click)="browse($event)">browse</button>
    </p>
    <p>Supports: {{extensionsFormatted}} Maximum file size: {{maxSizeFormatted}}</p>
  </div>
  <div *ngIf="file">
    <p>{{file.name}}
      <app-remove-button label="Remove file" (action)="remove()"></app-remove-button>
    </p>
    <p class="file-size">{{file.formattedSize}}</p>
  </div>
</label>