import { Attribute, Component, Input, OnInit, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-label-and-tooltip',
  templateUrl: './label-and-tooltip.component.html',
  styleUrls: ['./label-and-tooltip.component.scss'],
})
export class LabelAndTooltipComponent implements OnInit {

  @Input() label: string;
  @Input() tooltip: string;
  @Input() danger: boolean;
  @Input() for: string;
  @Input() isLongLabel: boolean;
  @Input() uppercase: boolean;
  constructor() { }

  ngOnInit(): void {
    this.updateLabel();
  }

  ngOnChanges(){
    this.updateLabel();
  }

  updateLabel(){
    this.isLongLabel = this.label.length > 20;
  }
}
