import { ActionReducerMap } from '@ngrx/store';

import { AppState } from '../state/app.state';

import { notificationsReducer } from './notifications.reducer';
import { permissionsReducer } from './permissions.reducer';
import { rolesReducer } from './roles.reducer';
import { approvalWorkflowReducer } from './approval-workflow.reducer';
import { partnersReducer } from './partners.reducer';
import { companiesReducer } from './companies.reducer';


export const appReducer: ActionReducerMap<AppState, any> = {
  notifications: notificationsReducer,
  permissions: permissionsReducer,
  roles: rolesReducer,
  defaultRoles: rolesReducer,
  instructionApprovalWorkflow: approvalWorkflowReducer,
  invoiceApprovalWorkflow: approvalWorkflowReducer,
  partners: partnersReducer,
  companies: companiesReducer
};
