import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { JwtPayload } from 'jwt-decode';
import jwt_decode from "jwt-decode";
import { UserService } from '../user/user.service';
import { NameValuePair } from 'types/common';

type Environments = 'production' | 'development';
type ENV = 'PROD' | 'DEV' | 'TEST';
type Endpoints = {
  payoutURL: string,
  authURL: string,
  apiGatewayURL: string,
  eddaURL: string,
  environment: ENV,
  proxteraURL: string,
};
type Settings = {
  production: Endpoints,
  development: Endpoints,
};
export interface JWT_Payload extends JwtPayload {
  name: string,
  family_name: string,
  "cognito:groups": string[],
  "cognito:username": string,
  "custom:partnerId": string,
  partnerId: string,
  username: string,
  email: string,
  email_verified: boolean,
  token_use: string,
  event_id: string,
  auth_time: number,
  Permissions: string,
  Companies: string,
  companyId: string,
  companyName: string,

  full_name?: string,
  groups?: string[],
  isAdmin?: boolean,

  expiration: Date,
  isExpired(): boolean,
}
@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  public endpointSettings: Settings;
  public static ENDPOINTS: Endpoints;
  public static TOKEN: JWT_Payload;
  public static SESSION: {
    CompanyId: string,
    CompanyName: string,
  } = {
    CompanyId: null,
    CompanyName: null,
  };
  private proxy: boolean = false;
  constructor(public http: HttpClient) {
    this.readToken();
  }

  readToken(){
    const token = localStorage.getItem('liquid_payout_portal_id_token');
    if (token) {
      //const adminGroups = ['Administrator', 'Financeuser', 'ImplementationUser', 'SuperAdmin'];
      //from header
      const adminGroups = ['Administrator', 'Administrators', 'OrgAdmin', 'Financeuser', 'ImplementationUser', 'SuperAdmin'];
      try {
        ConfigService.TOKEN = jwt_decode(localStorage.getItem('liquid_payout_portal_id_token'));
      } catch (error) {
        if (error.message.includes('Invalid token specified')){
          console.warn('Invalid token specified. Please login again.');
        } else {
          console.warn(error);
        }
        localStorage.removeItem('liquid_payout_portal_id_token');
        return null;
      }
      ConfigService.TOKEN.groups = ConfigService.TOKEN['cognito:groups'];
      ConfigService.TOKEN.username = ConfigService.TOKEN['cognito:username'];
      ConfigService.TOKEN.partnerId = ConfigService.TOKEN['custom:partnerId']; // localStorage.getItem('liquid_payout_portal_partnerId')
      ConfigService.TOKEN.isAdmin = ConfigService.TOKEN.groups.some(role => adminGroups.includes(role));
      ConfigService.TOKEN.full_name = `${ConfigService.TOKEN.name} ${ConfigService.TOKEN.family_name}`.trim();
      ConfigService.TOKEN.Permissions = atob(ConfigService.TOKEN.Permissions);
      if(this.proxy && !this.isProdEnvironment()){
        ConfigService.TOKEN.exp += 9999999;
      }
      ConfigService.TOKEN.expiration = new Date(ConfigService.TOKEN.exp * 1000);
      ConfigService.TOKEN.isExpired = function(){ return this.expiration < new Date() };
    }
    return ConfigService.TOKEN;
  }

  getConfigSetup(): Observable<any> {
    return this.http.get('./assets/config/endpoint.json').pipe(map((response: Settings) => {
      if(this.proxy && response[environment.name as Environments].environment !== 'PROD'){
        Object.keys(response.development).forEach(x => {
          response.development[x] = response.development[x].replace('https://', 'http://localhost:3000/');
        });
      }
      this.endpointSettings = response;
      ConfigService.ENDPOINTS = response[environment.name as Environments];
      return response;
    }));
  }

  getEndpointPayoutUrl(){
    return ConfigService.ENDPOINTS.payoutURL;
  }

  getEndpointAuthUrl(){
    return ConfigService.ENDPOINTS.authURL;
  }

  getEndpointApiGateway(){
    return ConfigService.ENDPOINTS.apiGatewayURL;
  }

  getEndpointEddaApi(){
    return ConfigService.ENDPOINTS.eddaURL;
  }

  getEnvironment(){
    return ConfigService.ENDPOINTS.environment;
  }

  isProdEnvironment(): boolean {
    return ConfigService.ENDPOINTS?.environment === 'PROD';
  }

  getProxteraUrl(){
    return ConfigService.ENDPOINTS.proxteraURL;
  }

}
