import { Injectable } from '@angular/core';
import { switchMap, map, share, catchError } from 'rxjs/operators';
import { Observable, of, EMPTY, Subject, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OperationsService {
  public selectedInstructionToUpdate: string = '';
  public selectedInstructionId: string = '';

  constructor() { }

  setSelectedInstructionToUpdate(instructionId){
    this.selectedInstructionToUpdate = instructionId;
  }

  setSelectedInstructionId(instructionId){
    this.selectedInstructionId = instructionId;
  }

  resetCompanyToUpdate(){
    this.selectedInstructionToUpdate = '';
  }

  resetSelectedInstructionId(){
    this.selectedInstructionId = '';
  }
}
