import { Injectable } from '@angular/core';
import { ApiService } from '../api/api.service';
import { switchMap, map, share, catchError } from 'rxjs/operators';
import { Observable, of, EMPTY } from 'rxjs';
import { Router } from '@angular/router';
import { HttpClient, HttpResponse, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { NotifierService } from 'angular-notifier';
import { ConfigService, JWT_Payload } from '../config/config.service';
import { error } from 'protractor';
import jwt_decode from "jwt-decode";
import { PermissionService } from '../permission/permission.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  constructor(
    private apiService: ApiService,
    private router: Router,
    private http: HttpClient,
    private notifierService: NotifierService,
    private configService: ConfigService,
    private permissionsService: PermissionService,
    ) {
      this.readToken();
    }

    readToken(){
      this.configService.readToken();
    }
    
    initUserInfo(){
      try {
        if (!ConfigService.TOKEN){
          return of(false);
        }
        console.log('Token:', ConfigService.TOKEN);
        if (ConfigService.TOKEN.isExpired()){
          console.log('⏳ Token expired');
          return of(false);
        }
        this.getUserDetails(ConfigService.TOKEN.username).subscribe(userDetails => {
          this.getRolesPermissions(ConfigService.TOKEN.partnerId, userDetails.roles[0], true).subscribe(list => {
            this.permissionsService.updatePermissionSelection(list);
            return of(true);
          }, error => {
            return of(false);
          });
        }, error => {
          return of(false);
        });
        return of(false);
      } catch (error) {
        return of(false);
      }
    }
    
    getUsers(queryString: string){
      let headers = new HttpHeaders({
        authorization: 'bearer ' + localStorage.getItem('liquid_payout_portal_id_token')
      })
      
      return this.http.get(this.configService.getEndpointAuthUrl() + `/users${queryString}`, {headers: headers}).pipe(map((response: any) => {
        return response;
      }), catchError(err => {
        
        console.error('An error occurred:', err.status);
        if (err.status != 403){
          this.notifierService.notify('error', 'Something went wrong');
        }
        return err;
      }));
    }
    
    createRole(role){
      let headers = new HttpHeaders({
        authorization: 'bearer ' + localStorage.getItem('liquid_payout_portal_id_token')
      })
      
      return this.http.post(this.configService.getEndpointAuthUrl() + `/groups`, role, {headers: headers}).pipe(map((response: any) => {
        return response;
      }), catchError(err => {
        console.log(err);
        console.error('An error occurred:', err.status);
        if (err.error.ResponseStatus.Description){
          this.notifierService.notify('error', err.error.ResponseStatus.Description);
        } else {
          this.notifierService.notify('error', 'Something went wrong');
        }
        return err;
      }));
    }
    
    getUserDetails(userName: string){
      let headers = new HttpHeaders({
        authorization: 'bearer ' + localStorage.getItem('liquid_payout_portal_id_token'),
      });
      return this.http.get(
        this.configService.getEndpointAuthUrl() + `/users/${userName}`,
        { headers: headers }
      ).pipe(
        map((response: any) => {
          return response;
        }), catchError((err, caught) => {
          console.error('An error occurred:', err.status);
          if (err.status != 403){
            this.notifierService.notify('error', 'Something went wrong');
          }
          return err;
        })
      );
    }
    
    getDefaultRoles(): Observable<any> {
      let headers = new HttpHeaders({
        authorization: 'bearer ' + localStorage.getItem('liquid_payout_portal_id_token')
      })
      
      return this.http.get(this.configService.getEndpointAuthUrl() + '/groups/roles/default', {headers: headers}).pipe(map((response: any) => {
        return response;
      }), catchError(err => {
        console.error('An error occurred:', err.status);
        this.notifierService.notify('error', 'Something went wrong');
        return err;
      }));
    }
    
    getRoles(partnerId: string): Observable<any> {
      let headers = new HttpHeaders({
        authorization: 'bearer ' + localStorage.getItem('liquid_payout_portal_id_token')
      })
      
      return this.http.get(this.configService.getEndpointAuthUrl() + `/roles/${partnerId}`, {headers: headers}).pipe(map((response: any) => {
        return response;
      }), catchError(err => {
        console.error('An error occurred:', err.status);
        if (err.status != 403){
          this.notifierService.notify('error', 'Something went wrong');
        }
        return err;
      }));
    }
    
    getRolesDefault(): Observable<any> {
      let headers = new HttpHeaders({
        authorization: 'bearer ' + localStorage.getItem('liquid_payout_portal_id_token')
      })
      
      return this.http.get(this.configService.getEndpointAuthUrl() + `/roles/default`, {headers: headers}).pipe(map((response: any) => {
        return response;
      }), catchError(err => {
        console.error('An error occurred:', err.status);
        if (err.status != 403){
          this.notifierService.notify('error', 'Something went wrong');
        }
        return err;
      }));
    }
    
    getDefaultRolesPermissions(roleKey: string): Observable<any> {
      let headers = new HttpHeaders({
        authorization: 'bearer ' + localStorage.getItem('liquid_payout_portal_id_token')
      })
      
      return this.http.get(this.configService.getEndpointAuthUrl() + `/roles/default/${roleKey}/permissions`, {headers: headers}).pipe(map((response: any) => {
        return response;
      }), catchError(err => {
        console.error('An error occurred:', err.status);
        this.notifierService.notify('error', 'Something went wrong');
        return err;
      }));
    }
    
    getRolesPermissions(partnerId: string, roleKey: string, force?: boolean): Observable<any> {
      let headers = new HttpHeaders({
        authorization: 'bearer ' + localStorage.getItem('liquid_payout_portal_id_token')
      })
      
      let fullString = '/' + force.toString();
      let emptyString = '';
      
      return this.http.get(this.configService.getEndpointAuthUrl() + `/roles/${partnerId}/${roleKey}/permissions${force == false ? fullString : emptyString }`, {headers: headers}).pipe(map((response: any) => {
        return response;
      }), catchError(err => {
        console.error('An error occurred:', err.status);
        this.notifierService.notify('error', 'Something went wrong');
        return err;
      }));
    }
    
    addPermission(partnerId: string, roleKey: string, permission): Observable<any> {
      let headers = new HttpHeaders({
        authorization: 'bearer ' + localStorage.getItem('liquid_payout_portal_id_token')
      })
      
      return this.http.post(this.configService.getEndpointAuthUrl() + `/roles/${partnerId}/${roleKey}/${permission}`, null, {headers: headers}).pipe(map((response: any) => {
        return response;
      }), catchError(err => {
        console.error('An error occurred:', err.status);
        this.notifierService.notify('error', 'Something went wrong');
        return err;
      }));
    }
    
    setMultiplePermissions(partnerId: string, roleKey: string, permissions: any): Observable<any> {
      let headers = new HttpHeaders({
        authorization: 'bearer ' + localStorage.getItem('liquid_payout_portal_id_token')
      })
      
      return this.http.post(this.configService.getEndpointAuthUrl() + `/roles/${partnerId}/${roleKey}/permissions`, permissions, {headers: headers}).pipe(map((response: any) => {
        return response;
      }), catchError(err => {
        console.error('An error occurred:', err.status);
        this.notifierService.notify('error', 'Something went wrong');
        return err;
      }));
    }
    
    deletePermission(partnerId: string, roleKey: string, permission): Observable<any> {
      let headers = new HttpHeaders({
        authorization: 'bearer ' + localStorage.getItem('liquid_payout_portal_id_token')
      })
      
      return this.http.delete(this.configService.getEndpointAuthUrl() + `/roles/${partnerId}/${roleKey}/${permission}`, {headers: headers}).pipe(map((response: any) => {
        return response;
      }), catchError(err => {
        console.error('An error occurred:', err.status);
        this.notifierService.notify('error', 'Something went wrong');
        return err;
      }));
    }
    
    getPermissions(): Observable<any> {
      let headers = new HttpHeaders({
        authorization: 'bearer ' + localStorage.getItem('liquid_payout_portal_id_token')
      })
      
      return this.http.get(this.configService.getEndpointAuthUrl() + `/permissions`, {headers: headers}).pipe(map((response: any) => {
        return response;
      }), catchError(err => {
        console.error('An error occurred:', err.status);
        this.notifierService.notify('error', 'Something went wrong');
        return err;
      }));
    }
    
    createUser(user): Observable<any> {
      let headers = new HttpHeaders({
        authorization: 'bearer ' + localStorage.getItem('liquid_payout_portal_id_token')
      })
      
      return this.http.post(this.configService.getEndpointAuthUrl() + '/users', user, {headers: headers}).pipe(map((response: any) => {
        return response;
      }), catchError(err => {
        console.log(err);
        console.error('An error occurred:', err.status);
        if (err.status != 409){
          this.notifierService.notify('error', err.error.ResponseStatus.Description);
        } else {
          console.log('Error', err);
          this.notifierService.notify('error', err.error.ResponseStatus.Description);
        }
        return err;
      }));
    }
    
    updateUserCompanies(userId: string, partnerId:string , companies: any[]): Observable<any> {
      let headers = new HttpHeaders({
        authorization: 'bearer ' + localStorage.getItem('liquid_payout_portal_id_token')
      })
      
      return this.http.post(
        `${this.configService.getEndpointAuthUrl()}/users/${userId}/partner/${partnerId}`,
        companies,
        { headers }
        ).pipe(map((response: any) => {
          return response;
        }), catchError(err => {
          console.warn('An error occurred:', err.status);
          console.warn('Error', err);
          this.notifierService.notify('error', err.error.ResponseStatus.Description);
          return err;
        }));
      }
      
      deleteUser(userName: string): Observable<any> {
        let headers = new HttpHeaders({
          authorization: 'bearer ' + localStorage.getItem('liquid_payout_portal_id_token')
        })
        
        return this.http.delete(this.configService.getEndpointAuthUrl() + `/users/${userName}`, {headers: headers}).pipe(map((response: any) => {
          this.notifierService.notify('success', `User ${userName} was successfully deleted`);
          return response;
        }), catchError(err => {
          console.error('An error occurred:', err.status);
          this.notifierService.notify('error', err.error.ResponseStatus.Description);
          return err;
        }));
      }
      
      deleteRole(partnerId: string, role: string): Observable<any> {
        let headers = new HttpHeaders({
          authorization: 'bearer ' + localStorage.getItem('liquid_payout_portal_id_token')
        })
        
        return this.http.delete(this.configService.getEndpointAuthUrl() + `/roles/${partnerId}/${role}`, {headers: headers}).pipe(map((response: any) => {
          this.notifierService.notify('success', `Role ${role} was successfully deleted`);
          return response;
        }), catchError(err => {
          console.error('An error occurred:', err.status);
          this.notifierService.notify('error', err.error.ResponseStatus.Description);
          return err;
        }));
      }
      
      deleteRoleFromUser(partnerId: string, role: string, username: string): Observable<any> {
        let headers = new HttpHeaders({
          authorization: 'bearer ' + localStorage.getItem('liquid_payout_portal_id_token')
        })
        
        return this.http.delete(this.configService.getEndpointAuthUrl() + `/roles/${partnerId}/${role}/users/${username}`, {headers: headers}).pipe(map((response: any) => {
          return response;
        }), catchError(err => {
          console.error('An error occurred:', err.status);
          this.notifierService.notify('error', err.error.ResponseStatus.Description);
          return err;
        }));
      }
      
      
      putRoleToUser(partnerId: string, role: string, username: string): Observable<any> {
        let headers = new HttpHeaders({
          authorization: 'bearer ' + localStorage.getItem('liquid_payout_portal_id_token')
        })
        
        return this.http.put(this.configService.getEndpointAuthUrl() + `/roles/${partnerId}/${role}/users`, {username: username}, {headers: headers}).pipe(map((response: any) => {
          this.notifierService.notify('success', `New role was successfully signed to user ${username}`);
          localStorage.removeItem('liquid_payout_portal_user_details');
          return response;
        }), catchError(err => {
          console.error('An error occurred:', err.status);
          this.notifierService.notify('error', err.error.ResponseStatus.Description);
          return err;
        }));
      }
      
      addUserPicture(userName: string, file: File): Observable<any> {
        let headers = new HttpHeaders({
          authorization: 'bearer ' + localStorage.getItem('liquid_payout_portal_id_token')
        });
        
        const formData = new FormData();
        formData.append('userPictureFile', file);
        
        return this.http.post(this.configService.getEndpointAuthUrl() + `/users/${userName}/picture`, formData, {headers: headers}).pipe(map((response: any) => {
          return response;
        }), catchError(err => {
          console.log(err);
          console.error('An error occurred:', err.status);
          this.notifierService.notify('error', err.error.ResponseStatus.Description);
          return err;
        }));
      }
      
      putUserToGroup(groupName, userName, groupType?): Observable<any> {
        let headers = new HttpHeaders({
          authorization: 'bearer ' + localStorage.getItem('liquid_payout_portal_id_token')
        })
        
        let body = {
          userName: userName
        }
        if (groupType){
          body['groupType'] = groupType;
        }
        return this.http.put(this.configService.getEndpointAuthUrl() + `/groups/${groupName}/users`, body, {headers: headers}).pipe(map((response: any) => {
          return response;
        }), catchError(err => {
          console.error('An error occurred:', err.status);
          this.notifierService.notify('error', err.error.ResponseStatus.Description);
          return err;
        }));
      }
      
      createCompanyGroup(companyId): Observable<any> {
        let headers = new HttpHeaders({
          authorization: 'bearer ' + localStorage.getItem('liquid_payout_portal_id_token')
        })
        
        return this.http.post(this.configService.getEndpointAuthUrl() + `/groups/`, {groupName: companyId, groupType: 'C'}, {headers: headers}).pipe(map((response: any) => {
          return response;
        }), catchError(err => {
          if (err.status == 201 || err.status == 409 || err.status == 400){
            return of('true');
          } else {
            console.error('An error occurred:', err.status);
            this.notifierService.notify('error', err.error.ResponseStatus.Description);
            return err;
          }
        }));
      }
    }
    