import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ApiService } from './services/api/api.service';
import { InstructionService } from './services/instruction/instruction.service';
import { MerchantService } from './services/merchant/merchant.service';
import { InvoiceService } from './services/invoice/invoice.service';
import { ReportService } from './services/report/report.service';
import { UserService } from './services/user/user.service';
import { PartnerService } from './services/partner/partner.service';
import { SelfOnboardingService } from './services/self-onboarding/self-onboarding.service';
import { MyInfoService } from './services/my-info/my-info.service';
import { AccountService } from './services/account/account.service';
import { ClickOutsideModule } from 'ng-click-outside';
import { OrderModule } from 'ngx-order-pipe';
import { CompanySelectComponent } from './components/company-select/company-select.component';
import { LiquidCustomInputComponent } from './components/liquid-custom-input/liquid-custom-input.component';
import { MonthInputComponent } from './components/month-input/month-input.component';
import { LiquidModalComponent } from './components/liquid-modal/liquid-modal.component';
import { LiquidFormInputComponent } from './components/liquid-form-input/liquid-form-input.component';
import { LabelAndTooltipComponent } from './components/label-and-tooltip/label-and-tooltip.component';
import { TwoDigitDecimaNumberDirective } from './directives/decimal/decimal.directive';
import { CheckboxComponent } from './components/checkbox/checkbox.component';
import { OtpComponent } from './components/otp/otp.component';
import { CardComponent } from './components/card/card.component';
import { SettingsMenuComponent } from './components/settings-menu/settings-menu.component';
import { WizardStepsComponent } from './components/wizard-steps/wizard-steps.component';
import { FileUploadComponent } from './components/file-upload/file-upload.component';
import { NgxUploaderModule } from 'ngx-uploader';
import { RemoveButtonComponent } from './components/remove-button/remove-button.component';
import { SliderComponent } from './components/slider/slider.component';
import { CurrencyConverterComponent } from './components/currency-converter/currency-converter.component';
import { WizardHeaderComponent } from './components/wizard-header/wizard-header.component';
import { CarouselComponent } from './components/carousel/carousel.component';
import { AccountWidgetComponent } from './components/account-widget/account-widget.component';

@NgModule({
  declarations: [
    CompanySelectComponent,
    LiquidCustomInputComponent,
    MonthInputComponent,
    LiquidModalComponent,
    LiquidFormInputComponent,
    LabelAndTooltipComponent,
    TwoDigitDecimaNumberDirective,
    CheckboxComponent,
    OtpComponent,
    CardComponent,
    SettingsMenuComponent,
    WizardStepsComponent,
    WizardHeaderComponent,
    FileUploadComponent,
    RemoveButtonComponent,
    SliderComponent,
    CurrencyConverterComponent,
    WizardHeaderComponent,
    CarouselComponent,
    AccountWidgetComponent,
  ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    RouterModule,
    ClickOutsideModule,
    OrderModule,
    NgxUploaderModule,
  ],
  exports: [
    CompanySelectComponent,
    LiquidCustomInputComponent,
    MonthInputComponent,
    LiquidModalComponent,
    LiquidFormInputComponent,
    LabelAndTooltipComponent,
    TwoDigitDecimaNumberDirective,
    CheckboxComponent,
    OtpComponent,
    CardComponent,
    SettingsMenuComponent,
    WizardStepsComponent,
    WizardHeaderComponent,
    FileUploadComponent,
    RemoveButtonComponent,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    SliderComponent,
    CurrencyConverterComponent,
    CarouselComponent,
    AccountWidgetComponent,
  ],
  providers: [
    ApiService,
    InstructionService,
    InvoiceService,
    ReportService,
    UserService,
    MerchantService,
    PartnerService,
    SelfOnboardingService,
    WizardStepsComponent,
    MyInfoService,
    AccountService,
  ]
})
export class SharedModule { }
