import { EnumCompaniesActions } from '../actions/companies.actions';
import { CompaniesActions } from '../actions/companies.actions';
import { initialCompaniesState} from '../state/companies.state';

export function companiesReducer(
  state = initialCompaniesState,
  action: CompaniesActions
) {
  switch (action.type) {
    case EnumCompaniesActions.getCompaniesSuccess:
      return {
        ...state,
        companies: action.payload.data
      };
    default:
      return state;
  }
}
