import { Component, EventEmitter, OnInit, Input, Output, ViewChild, SimpleChange } from '@angular/core';

@Component({
  selector: 'app-otp',
  templateUrl: './otp.component.html',
  styleUrls: ['./otp.component.scss']
})
export class OtpComponent implements OnInit {
  @ViewChild('input1') input1;
  @ViewChild('input2') input2;
  @ViewChild('input3') input3;
  @ViewChild('input4') input4;
  @ViewChild('input5') input5;
  @ViewChild('input6') input6;

  otp1: string;
  otp2: string;
  otp3: string;
  otp4: string;
  otp5: string;
  otp6: string;

  digits: number = 6;

  @Input() onReset: boolean;
  ngOnChanges(changes: SimpleChange ) {
    this.init();
  }

  constructor() { }
  ngOnInit(): void { }

  init(){
    this.otp1 = null;
    this.otp2 = null;
    this.otp3 = null;
    this.otp4 = null;
    this.otp5 = null;
    this.otp6 = null;
    setTimeout(() => {
      this.input1?.nativeElement?.focus();
    }, 500)
  }

  @Output() value: EventEmitter<string> = new EventEmitter<string>();
  
  onInput(event, currentInput){
    if (event.inputType === "insertFromPaste"){
      event.preventDefault();
      return;
    }

    const isDelete = event.inputType === 'deleteContentForward';
    const isBackspace = event.inputType === 'deleteContentBackward';

    const value = parseInt(this[`otp${currentInput}`]);
    if(!Number.isInteger(value) && !isDelete && !isBackspace){
      event.preventDefault();
      this[`otp${currentInput}`] = value || '';
      this[`input${currentInput}`].nativeElement.value = value || '';
      return;
    }

    if (currentInput < this.digits && !isBackspace)
      this[`input${currentInput + 1}`].nativeElement.focus();
    else if (1 < currentInput && isBackspace && !isDelete)
      this[`input${currentInput - 1}`].nativeElement.focus();
    
    this.validate();
  }

  onPaste(event){
    const paste = event.clipboardData.getData("text").replace(/\s/g,'').substring(0, this.digits);

    if (!parseInt(paste)){
      event.preventDefault()
      return;
    }

    this.otp1 = paste[0];
    this.otp2 = paste[1];
    this.otp3 = paste[2];
    this.otp4 = paste[3];
    this.otp5 = paste[4];
    this.otp6 = paste[5];
    this.input6.nativeElement.focus();

    this.validate();
  }

  validate(){
    const valid = [this.otp1, this.otp2, this.otp3, this.otp4, this.otp5, this.otp6]
      .every(digit => Number.isInteger(Number.parseInt(digit)));
    this.value.emit(valid
      ? '' + this.otp1 + this.otp2 + this.otp3 + this.otp4 + this.otp5 + this.otp6
      : '');
  }
}
