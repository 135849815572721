import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-account-widget',
  templateUrl: './account-widget.component.html',
  styleUrls: ['./account-widget.component.scss']
})
export class AccountWidgetComponent implements OnInit {
  constructor() { }
  ngOnInit(): void { }
  @Input() currency: string;
  @Input() currencyName: string;
  @Input() countryFlag: string = 'SG';
  @Input() availableBalance: number = 0;
  @Input() earmarkedBalance: number = 0;
}
