import { Injectable } from '@angular/core';
import { Observable, of, EMPTY } from 'rxjs';
import { HttpClient, HttpResponse, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { switchMap, map, share, catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import { ConfigService } from '../config/config.service';

@Injectable({
  providedIn: 'any'
})
export class ApiService {

  constructor(private http: HttpClient, private router: Router, private notifier: NotifierService, private configService: ConfigService) { }

  get(url: string): Observable<any> {
    let headers = this.getHeader();
    return this.http.get(this.configService.getEndpointPayoutUrl() + url, { headers: headers }).pipe(share()).pipe(map((response: any) => {
      return response;
    }), catchError(err => {
      this.validateError(err);
      throw err;
    }));
  }

  delete(url: string): Observable<any> {
    let headers = this.getHeader();

    return this.http.delete(this.configService.getEndpointPayoutUrl() + url, { headers: headers }).pipe(share()).pipe(map((response: any) => {
      return response;
    }), catchError(err => {
      this.validateError(err);
      return EMPTY;
    }));
  }

  post(url: string, body: any = null, responseType: any = 'json', customException: boolean = false): Observable<any> {
    const headers = this.getHeader();
    return this.http.post(
      this.configService.getEndpointPayoutUrl() + url,
      body,
      { headers, responseType }
    ).pipe(share()).pipe(map((response: any) => {
      return response;
    }), catchError(err => {
      const errorMessage = this.validateError(err, customException);
      if (customException && errorMessage !== EMPTY) {
        return err;
      } else {
        return EMPTY;
      }
    }));
  }

  download(url: string, body?: any): Observable<any> {
    let headers = this.getHeader();
    return this.http.post(
      this.configService.getEndpointPayoutUrl() + url,
      (body ? body : null),
      {
        headers,
        responseType: 'blob'
      },
    ).pipe(share()).pipe(map((response: any) => {
      return response;
    }), catchError(err => {
      return this.validateError(err);
    }));
  }

  update(url: string, body: any): Observable<any> {
    let headers = this.getHeader();
    return this.http.put(this.configService.getEndpointPayoutUrl() + url, (body ? body : null), { headers: headers }).pipe(share()).pipe(map((response: any) => {
      return response;
    }), catchError(err => {
      console.error('An error occurred:', err.status);
      return this.validateError(err);
    }));
  }

  patch(url: string, body: any): Observable<any> {
    let headers = this.getHeader();
    return this.http.patch(this.configService.getEndpointPayoutUrl() + url, (body ? body : null), { headers: headers }).pipe(share()).pipe(map((response: any) => {
      return response;
    }), catchError(err => {
      console.error('An error occurred:', err.status);
      return this.validateError(err);
    }));
  }

  validateError(error, customException: boolean = false) {
    if (error.status === 401 || error.status === 0 || error.status === 403) {
      if (!localStorage.getItem('liquid_payout_portal_current_route')){
        localStorage.setItem('liquid_payout_portal_current_route', this.router.url);
      }
      this.router.navigateByUrl('/login');
    } else {
      console.log(error);
      const errorMessage = typeof error.error === 'string' ? error.error : error.error?.ResponseStatus?.Description;
      if (customException){
        return errorMessage;
      } else if (errorMessage){
        this.notifier.notify('error', errorMessage);
      } else if (error.status === 404){
        this.notifier.notify('error', 'Request not found');
      } else {
        this.notifier.notify('error', 'Something went wrong');
      }
    }
    return EMPTY;
  }

  getHeader() {
    let headers = new HttpHeaders({
      authorization: 'bearer ' + localStorage.getItem('liquid_payout_portal_id_token')
    });
    headers = headers.append('Access-Control-Allow-Origin', '*');
    headers = headers.append('Access-Control-Allow-Methods', 'GET, POST, PATCH, PUT, DELETE, OPTIONS');
    headers = headers.append('Access-Control-Allow-Headers', 'Origin, Content-Type, X-Auth-Token');
    return headers;
  }
}
