import { Action, createAction, props } from '@ngrx/store';

export enum EnumNotificationsActions {
  getNotifications = '[Notification] get Notifications',
  getNotificationsSuccess = '[Notification] get Notifications Success',
  getInvoices = '[Notification] get Invoices',
}

export class getNotifications implements Action {
  public readonly type = EnumNotificationsActions.getNotifications;
  constructor(){}
}

export class getNotificationsSuccess implements Action {
  public readonly type = EnumNotificationsActions.getNotificationsSuccess;
  constructor(public payload: any){}
}

export class getInvoices implements Action {
  public readonly type = EnumNotificationsActions.getInvoices;
  constructor(public payload: any){}
}

export type NotificationsActions = getNotifications | getNotificationsSuccess | getInvoices;
