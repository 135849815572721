import { Injectable } from '@angular/core';
import { ApiService } from '../api/api.service';
import { switchMap, map, share, catchError } from 'rxjs/operators';
import { Observable, of, EMPTY } from 'rxjs';
import { Router } from '@angular/router';
import { HttpClient, HttpResponse, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { NotifierService } from 'angular-notifier';
import { ConfigService } from '../config/config.service';
import { error } from 'protractor';

@Injectable({
  providedIn: 'root'
})
export class MerchantService {

  constructor(private apiService: ApiService, private router: Router, private http: HttpClient,
    private notifierService: NotifierService, private configService: ConfigService) {
    }

  getCustomerById(customerId: string){
    let headers = new HttpHeaders({
      authorization: 'bearer ' + localStorage.getItem('liquid_payout_portal_id_token')
    })
    headers = headers.append('Access-Control-Allow-Origin', '*');
    headers = headers.append('Access-Control-Allow-Methods', 'GET, POST, PATCH, PUT, DELETE, OPTIONS');
    headers = headers.append('Access-Control-Allow-Headers', 'Origin, Content-Type, X-Auth-Token');

    return this.http.get(this.configService.getEndpointApiGateway() + `/partner/api/kyc/customer/${customerId}`, {headers: headers}).pipe(map((response: any) => {
      return response;
    }), catchError(err => {

      console.error('An error occurred:', err.status);
      if (err.status != 403){
        this.notifierService.notify('error', 'No Customer is found, Please enter correct CustomerId');
      }
      return err;
    }));
  }


  getPartners(){
    return this.apiService.get('/partners?Page=1&PageSize=100&IsCompanyAssociatedOnly=true').pipe(switchMap((response: any) => {
      return of(response);
    }))
  }

  getAllPartners(){
    return this.apiService.get('/partners?Page=1&PageSize=100&IsCompanyAssociatedOnly=false').pipe(switchMap((response: any) => {
      return of(response);
    }))
  }

  createPartner(partner){
    return this.apiService.post(`/partners`, partner).pipe(switchMap((response: any) => {
      return of(response);
    }))
  }

  updatePartner(partner){
    return this.apiService.update(`/partners/${partner.partnerId}`, partner).pipe(switchMap((response: any) => {
      return of(response);
    }))
  }

  deletePartner(partnerId){
    return this.apiService.delete(`/partners/${partnerId}`).pipe(switchMap((response: any) => {
      return of(response);
    }))
  }

  registerMerchant(merchantForm){
    let headers = new HttpHeaders({
      authorization: 'bearer ' + localStorage.getItem('liquid_payout_portal_id_token')
    })
    headers = headers.append('Access-Control-Allow-Origin', '*');
    headers = headers.append('Access-Control-Allow-Methods', 'GET, POST, PATCH, PUT, DELETE, OPTIONS');
    headers = headers.append('Access-Control-Allow-Headers', 'Origin, Content-Type, X-Auth-Token');

    return this.http.post(this.configService.getEndpointApiGateway() + `/partner/api/merchant/create`, merchantForm, {headers: headers}).pipe(map((response: any) => {
      this.notifierService.notify('success','Merchant was successfully registered');
      return response;
    }), catchError(err => {

      console.error('An error occurred:', err.status);
      if (err.status != 403){
        if (err.error && err.error.ResponseStatus.Description){
          this.notifierService.notify('error', err.error.ResponseStatus.Description);
        } else {
          this.notifierService.notify('error', 'Something went wrong');
        }
      } else {
      }
      return err;
    }));
  }

  createBankAccount(bankAccount){
    let headers = new HttpHeaders({
      authorization: 'bearer ' + localStorage.getItem('liquid_payout_portal_id_token')
    })
    headers = headers.append('Access-Control-Allow-Origin', '*');
    headers = headers.append('Access-Control-Allow-Methods', 'GET, POST, PATCH, PUT, DELETE, OPTIONS');
    headers = headers.append('Access-Control-Allow-Headers', 'Origin, Content-Type, X-Auth-Token');

    return this.http.post(this.configService.getEndpointApiGateway() + `/partner/api/bankaccount/create`, bankAccount, {headers: headers}).pipe(map((response: any) => {
      return response;
    }), catchError(err => {

      console.error('An error occurred:', err.status);
      if (err.status != 403){
        this.notifierService.notify('error', 'Something went wrong');
      }
      return err;
    }));
  }

  getMerchants(queryString, partnerId){
    let headers = new HttpHeaders({
      authorization: 'bearer ' + localStorage.getItem('liquid_payout_portal_id_token')
    })
    headers = headers.append('Access-Control-Allow-Origin', '*');
    headers = headers.append('Access-Control-Allow-Methods', 'GET, POST, PATCH, PUT, DELETE, OPTIONS');
    headers = headers.append('Access-Control-Allow-Headers', 'Origin, Content-Type, X-Auth-Token');

    return this.http.get(this.configService.getEndpointApiGateway() + `/partner/api/merchant${queryString}&PartnerId=${partnerId}`, {headers: headers}).pipe(map((response: any) => {
      return response;
    }), catchError(err => {

      console.error('An error occurred:', err.status);
      if (err.status != 403){
        this.notifierService.notify('error', 'Something went wrong');
      }
      return err;
    }));
  }

  getBankAccounts(clientId){
    let headers = new HttpHeaders({
      authorization: 'bearer ' + localStorage.getItem('liquid_payout_portal_id_token')
    })
    headers = headers.append('Access-Control-Allow-Origin', '*');
    headers = headers.append('Access-Control-Allow-Methods', 'GET, POST, PATCH, PUT, DELETE, OPTIONS');
    headers = headers.append('Access-Control-Allow-Headers', 'Origin, Content-Type, X-Auth-Token');

    return this.http.get(this.configService.getEndpointApiGateway() + `/partner/api/merchant/${clientId}/accounts`, {headers: headers}).pipe(map((response: any) => {
      return response;
    }), catchError(err => {

      console.error('An error occurred:', err.status);
      if (err.status != 403){
        this.notifierService.notify('error', 'Something went wrong');
      }
      return err;
    }));
  }

  getMerchantQrString(clientId, partnerId){
    let headers = new HttpHeaders({
      authorization: 'bearer ' + localStorage.getItem('liquid_payout_portal_id_token')
    })
    headers = headers.append('Access-Control-Allow-Origin', '*');
    headers = headers.append('Access-Control-Allow-Methods', 'GET, POST, PATCH, PUT, DELETE, OPTIONS');
    headers = headers.append('Access-Control-Allow-Headers', 'Origin, Content-Type, X-Auth-Token');

    return this.http.get(this.configService.getEndpointApiGateway() + `/partner/api/merchant/${clientId}/qr`, {headers: headers}).pipe(map((response: any) => {
      return response;
    }), catchError(err => {

      console.error('An error occurred:', err.status);
      if (err.status != 403){
        this.notifierService.notify('error', 'Something went wrong');
      }
      return err;
    }));
  }

  updateMerchant(merchant, merchantId){
    let headers = new HttpHeaders({
      authorization: 'bearer ' + localStorage.getItem('liquid_payout_portal_id_token')
    })
    headers = headers.append('Access-Control-Allow-Origin', '*');
    headers = headers.append('Access-Control-Allow-Methods', 'GET, POST, PATCH, PUT, DELETE, OPTIONS');
    headers = headers.append('Access-Control-Allow-Headers', 'Origin, Content-Type, X-Auth-Token');

    return this.http.put(this.configService.getEndpointApiGateway() + `/partner/api/merchant/${merchantId}`, merchant, {headers: headers}).pipe(map((response: any) => {
      return response;
    }), catchError(err => {

      console.error('An error occurred:', err.status);
      if (err.status != 403){
        this.notifierService.notify('error', 'Something went wrong');
      }
      return err;
    }));
  }

  deactivateMerchant(deactivationObject){
    let headers = new HttpHeaders({
      authorization: 'bearer ' + localStorage.getItem('liquid_payout_portal_id_token')
    })
    headers = headers.append('Access-Control-Allow-Origin', '*');
    headers = headers.append('Access-Control-Allow-Methods', 'GET, POST, PATCH, PUT, DELETE, OPTIONS');
    headers = headers.append('Access-Control-Allow-Headers', 'Origin, Content-Type, X-Auth-Token');

    return this.http.post(this.configService.getEndpointApiGateway() + `/partner/api/merchant/${deactivationObject.merchantId}/deactivate`, deactivationObject, {headers: headers}).pipe(map((response: any) => {
      this.notifierService.notify('success', 'PayNow Account was successfully deactivated');
      return true;
    }), catchError(err => {

      console.error('An error occurred:', err.status);

      if (err && err.error && err.error.ResponseStatus.Description){
        this.notifierService.notify('error', err.error.ResponseStatus.Description);
      } else {
        this.notifierService.notify('error', 'Something went wrong');
      }
      return err;
    }));
  }

  updateBankAccount(bankAccount, clientId){
    let headers = new HttpHeaders({
      authorization: 'bearer ' + localStorage.getItem('liquid_payout_portal_id_token')
    })
    headers = headers.append('Access-Control-Allow-Origin', '*');
    headers = headers.append('Access-Control-Allow-Methods', 'GET, POST, PATCH, PUT, DELETE, OPTIONS');
    headers = headers.append('Access-Control-Allow-Headers', 'Origin, Content-Type, X-Auth-Token');

    return this.http.put(this.configService.getEndpointApiGateway() + `/partner/api/bankaccount/${clientId}`, bankAccount, {headers: headers}).pipe(map((response: any) => {
      this.notifierService.notify('success','Merchant was successfully updated');
      return response;
    }), catchError(err => {

      console.error('An error occurred:', err.status);
      if (err.status != 403){
        this.notifierService.notify('error', 'Something went wrong');
      }
      return err;
    }));
  }

  activateMerchant(merchantId, partnerId){
    let headers = new HttpHeaders({
      authorization: 'bearer ' + localStorage.getItem('liquid_payout_portal_id_token')
    })
    headers = headers.append('Access-Control-Allow-Origin', '*');
    headers = headers.append('Access-Control-Allow-Methods', 'GET, POST, PATCH, PUT, DELETE, OPTIONS');
    headers = headers.append('Access-Control-Allow-Headers', 'Origin, Content-Type, X-Auth-Token');

    return this.http.post(this.configService.getEndpointApiGateway() + `/partner/api/merchant/${merchantId}/activate`, {clientId: merchantId}, {headers: headers}).pipe(map((response: any) => {
      this.notifierService.notify('success','Merchant was successfully activated');
      return response;
    }), catchError(err => {

      console.error('An error occurred:', err.status);
      if (err.status != 403){
        this.notifierService.notify('error', 'Pay Now Registration could not be completed, Please try again');
      }
      return err;
    }));
  }
}
