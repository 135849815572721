import { Injectable } from '@angular/core';
import { switchMap, map, share, catchError } from 'rxjs/operators';
import { Observable, of, EMPTY, Subject, BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';
import { HttpClient, HttpResponse, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { ApiService } from '../api/api.service';
import { OperationsService } from '../operations/operations.service';
import { NotifierService } from 'angular-notifier';
import { ConfigService } from '../config/config.service';
// import { ApiService } from '../api/api.service';
type BaseCompany = {
  companyId: string,
  isMultiCurrency:boolean,
}
@Injectable({
  providedIn: 'root'
})
export class CompanyService {
  private selectedCompany: Subject<BaseCompany> = new Subject<BaseCompany>();
  public selectedCompanySource: Subject<number> = new Subject<number>();
  public selectedHeaderCompanySource: Subject<number> = new Subject<number>();
  public selectedMulticurrencySource: Subject<number> = new Subject<number>();

  public multicurrencyStatus: number = 0;

  constructor(
    private apiService: ApiService,
    private router: Router,
    private http: HttpClient,
    private notifierService: NotifierService,
    private operationsService: OperationsService,
    private configService: ConfigService
  ) {}

  public setSelectedCompany(company: BaseCompany){
    this.selectedCompany.next(company);
  }

  public updateCompanySelection(number){
    this.selectedCompanySource.next(number);
  }

  public updateMulticurrencySelection(number){
    this.multicurrencyStatus = number;
    this.selectedMulticurrencySource.next(number);
  }

  public updateHeaderCompanySelection(number){
    this.selectedHeaderCompanySource.next(number);
  }

  public getSelectedCompany(): Observable<BaseCompany> {
    return this.selectedCompany.asObservable();
  }

  public getSelectedCompanyMap(): Observable<number> {
    return this.selectedCompanySource.asObservable();
  }

  public getSelectedMulticurrencyMap(): Observable<number> {
    return this.selectedMulticurrencySource.asObservable();
  }

  public getHeaderSelectedCompanyMap(): Observable<number> {
    return this.selectedHeaderCompanySource.asObservable();
  }

  getCompanies(partnerId?: string){
    partnerId = partnerId || localStorage.getItem('liquid_payout_portal_partnerId');
    if (!partnerId || partnerId === 'null' || partnerId === 'undefined')
      partnerId = ConfigService.TOKEN?.partnerId;
    const query = partnerId ? '?partnerId=' + partnerId : '';
    return this.apiService.get(`/company${query}`).pipe(switchMap((response: any) => {
      return of(response);
    }))
  }

  getCompany(companyId: string){
    return this.apiService.get(`/company/${companyId}`).pipe(switchMap((response: any) => {
      return of(response);
    }))
  }

  getCompanyAccounts(){
    const companyId = localStorage.getItem('liquid_payout_portal_companyId');
    const partnerId = localStorage.getItem('liquid_payout_portal_partnerId');
    return this.apiService.get(`/company/${companyId}/currencyBalance?partnerId=${partnerId}`).pipe(switchMap((response: {
      currencyBalanceList: any[]
    }) => {
      const list = this.processAccountsBalanceList(response.currencyBalanceList);
      return of(list);
    }))
  }

  getCompanyAccountSummary(accountNumber?: string){
    const companyId = localStorage.getItem('liquid_payout_portal_companyId');
    const partnerId = localStorage.getItem('liquid_payout_portal_partnerId');
    const queryParams = {
      partnerId,
      accountNumber,
    };
    const queryString = Object.keys(queryParams)
      .filter(x => queryParams[x])
      .map(x => `${x}=${queryParams[x]}`)
      .join('&');
    return this.apiService.get(`/company/${companyId}/accountSummary?${queryString}`).pipe(switchMap((response: {
      accountSummaryList: any[],
      baseCurrency: string,
    }) => {
      response.accountSummaryList = this.processAccountsBalanceList(response.accountSummaryList);
      return of(response);
    }))
  }
  
  private processAccountsBalanceList(list: any[]) {
    return list.filter(x => x.currency).map(x => ({
      ...x,
      currencyName: this.getCurrencyName(x.currency),
      countryFlag: this.getIso2FromCurrency(x.currency),
    }));
  }

  private getCurrencyName(value: string){
    switch (value) {
      case "USD": return 'United States Dollar';
      case "CAD": return 'Canadian Dollar';
      case "SGD": return 'Singapore Dollar';
      case "EUR": return 'Euro';
      case "GHS": return 'Ghanaian Cedi';
      case "PHP": return 'Philippine Peso';
      case "AUD": return 'Australian Dollar';
      case "GBP": return 'British Pound';
      case "HKD": return 'Hong Kong Dollar';
      case "NZD": return 'New Zealand Dollar';
      case "INR": return 'Indian Rupee';
      case "IDR": return 'Indonesian Rupiah';
      case "JPY": return 'Japan Yen';
      case "MYR": return 'Malaysia Ringgit';
      case "CNY": return 'Chinese Yuan';
      case "THB": return 'Thailand Baht';
      case "KRW": return 'Korea (South) Won';
      case "CHF": return 'Switzerland Franc';
      default: return 'Singapore Dollar';
    }
  }

  getIso2FromCurrency(currency: string = 'SGD'){
    switch (currency) {
      case "USD": return 'US';
      case "CAD": return 'CA';
      case "SGD": return 'SG';
      case "INR": return 'IN';
      case "EUR": return 'EU';
      case "GHS": return 'GH';
      case "PHP": return 'PH';
      case "AUD": return 'AU';
      case "GBP": return 'GB';
      case "HKD": return 'HK';
      case "NZD": return 'NZ';
      default: return currency.substring(0,2);
    }
  }

  getCompanyLabelMapping(){
    return this.apiService.get(`/payout/instruction/mapping/${localStorage.getItem('liquid_payout_portal_companyId')}`).pipe(switchMap((response: any) => {
      return of(response);
    }))
  }

  createCompany(companyForm){
    return this.apiService.post(`/company`, companyForm).pipe(switchMap((response: any) => {
      return of(response);
    }))
  }

  deleteCompany(id){
    return this.apiService.delete(`/company/${id}`).pipe(switchMap((response: any) => {
      return of(response);
    }))
  }

  updateCompany(id, companyForm){
    return this.apiService.update(`/company/${id}`, companyForm).pipe(switchMap((response: any) => {
      return of(response);
    }))
  }

  // getBankAccounts(companyId: string){
  //   return this.apiService.get(`/company/${companyId}/bankAccounts`).pipe(switchMap((response: any) => {
  //     return of(response);
  //   }))
  // }
}
