import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Resolve, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { environment } from '../../../environments/environment';
import { UserService } from '../../shared/services/user/user.service';
import { ReplaySubject, Subscription } from 'rxjs';
import { switchMap, map, share, catchError } from 'rxjs/operators';
import jwt_decode from "jwt-decode";
import { ConfigService } from 'src/app/shared/services/config/config.service';
import { PartnerService } from 'src/app/shared/services/partner/partner.service';
import * as moment from 'moment';


@Injectable({
  providedIn: 'root'
})
export class TokenGuard implements CanActivate {
  private tokenVerified: boolean = false;
  constructor(
    private router: Router,
    private userService: UserService
  ) { }

  private cannot(){
    sessionStorage.clear();
    localStorage.clear();
    ConfigService.TOKEN = null;
    PartnerService.Partners = null;
    this.router.navigateByUrl('/login');
    return of(false);
  }
  
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    console.log('🪙 Check token');
    if (!ConfigService.TOKEN){
      this.userService.readToken();
      if (!ConfigService.TOKEN)
        return this.cannot();
    }
    console.log('🔑 Check session');
    const isVerified = sessionStorage.getItem('token_verified');
    if (isVerified === 'true'){
      if (ConfigService.TOKEN.isExpired()) {
        console.log('🔑 Session ok, token expired 🛑');
        return this.cannot();
      } else {
        const expiration = moment(ConfigService.TOKEN.expiration).format('HH:mm');
        console.log(`🔑 Session ok, valid until: ${expiration} 🟢`);
        return of(true);
      }
    }
    return this.userService.initUserInfo().pipe(canActivate => {
      if (!canActivate) {
        console.log('🔑 Session not ok 🛑');
        return this.cannot();
      } else {
        const expiration = moment(ConfigService.TOKEN.expiration).format('HH:mm');
        console.log(`🔑 Setting session, valid until: ${expiration} 🟢`);
        sessionStorage.setItem('token_verified', 'true');
        return of(true);
      }
    });
    // return of(false);
    // if (localStorage.getItem('liquid_payout_portal_token_verified') == 'true'){
    //   console.log('tokenVerified=true');
    //   return of(true);
    // } else {
    //   return this.userService.getUserDetails(jwt_decode(localStorage.getItem('liquid_payout_portal_id_token'))['email']).pipe(map(result => {
    //     localStorage.setItem('liquid_payout_portal_token_verified', 'true');
    //     console.log('tokenVerified=true');
    //     return true;
    //   }), catchError((err: any) => {
    //     this.router.navigateByUrl('/login');
    //     return of(false);
    //   }));
    // }
  }
}
