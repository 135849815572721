<app-label-and-tooltip
  [uppercase]="uppercase"
  [for]="(list ? 'select-input-' : 'form-input-') + uuid"
  [label]="label"
  [isLongLabel]="label.length > 30"
  [danger]="!control?.valid && showErrors"
  [tooltip]="!control?.valid ? errorMessage : (required ? errorMessages.required : tooltip || '')">
</app-label-and-tooltip>
<app-liquid-custom-input *ngIf="list && !isDisabled"
  [outerId]="'select-input-' + uuid"
  [selectedValue]="selectedValue"
  [list]="list" [label]="'name'" [value]="'value'"
  [type]="'wide'" [length]="'max'"
  [customCssClass]="'form-select always-show'"
  (changed)="handleChange($event)"
  (changedMultiple)="handleChangeMultiple($event)"
  [readonly]="isDisabled"
  [multiselect]="multiselect">
</app-liquid-custom-input>
<input *ngIf="!list || isDisabled" [(ngModel)]="selectedValue || value" #input [id]="'form-input-' + uuid"
  [type]="type || 'text'"
  [attr.min]="min"
  [attr.maxlength]="maxLength"
  [placeholder]="placeholder || placeholderVerb + label"
  (ngModelChange)="handleChange($event)"
  class="liquid-payout-input small-placeholder-input {{customCssClass}}"
  [class.show-errors]="showErrors"
  [disabled]="isDisabled"
  [readonly]="isDisabled"
  [searchBar]="searchBar" />