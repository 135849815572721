import { Action, createAction, props } from '@ngrx/store';

export enum EnumRolesActions {
  getRoles = '[Role] get Roles',
  getRolesSuccess = '[Role] get Roles Success',
  getDefaultRoles = '[Role] get default Roles',
  getDefaultRolesSuccess = '[Role] get default Roles Success'
}

export class getRoles implements Action {
  public readonly type = EnumRolesActions.getRoles;
  constructor(public payload: any){}
}

export class getRolesSuccess implements Action {
  public readonly type = EnumRolesActions.getRolesSuccess;
  constructor(public payload: any){}
}

export class getDefaultRoles implements Action {
  public readonly type = EnumRolesActions.getDefaultRoles;
  constructor(public payload: any){}
}

export class getDefaultRolesSuccess implements Action {
  public readonly type = EnumRolesActions.getDefaultRolesSuccess;
  constructor(public payload: any){}
}

export type RolesActions = getRoles | getRolesSuccess | getDefaultRoles | getDefaultRolesSuccess;
