import { Injectable } from '@angular/core';
import { switchMap, map, share, catchError } from 'rxjs/operators';
import { Observable, of, EMPTY, Subject, BehaviorSubject } from 'rxjs';
// import { ApiService } from '../api/api.service';

import { Store, State } from '@ngrx/store';

import { AppState } from '../../../store/state/app.state';

@Injectable({
  providedIn: 'root'
})
export class PermissionService {
  public selectedPermissionSource: Subject<any> = new Subject<any>();
  public permissions: any[] = [];
  public static PermissionsList: {
    id: number,
    name: string,
    description: string,
  }[];

  constructor() { }

  public updatePermissionSelection(permissions){
    //console.log('updatePermissionSelection', permissions);
    PermissionService.PermissionsList = permissions;
    const obj = {
      permissions: permissions
    }
    this.permissions = permissions;
    this.selectedPermissionSource.next(obj);
  }

  public getSelectedPermissionMap(): Observable<any> {
    return this.selectedPermissionSource.asObservable();
  }
}
