import { Injectable } from '@angular/core';
import { ApiService } from '../api/api.service';
import { switchMap, map, share, catchError } from 'rxjs/operators';
import { Observable, of, EMPTY } from 'rxjs';
import { Router } from '@angular/router';
import { HttpClient, HttpResponse, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { NotifierService } from 'angular-notifier';
import * as FileSaver from 'file-saver';
import { ConfigService } from '../config/config.service';

@Injectable({
  providedIn: 'any'
})
export class ReportService {

  constructor(private apiService: ApiService, private router: Router, private http: HttpClient,
  private notifierService: NotifierService, private configService: ConfigService) { }

  getReports(queryString: string){
    return this.apiService.get(`/report/payout${queryString}`).pipe(switchMap((response: any) => {
      return of(response);
    }))
  }

  uploadMonthReport(){
    let headers = new HttpHeaders({
      authorization: 'bearer ' + localStorage.getItem('liquid_payout_portal_id_token')
    });
    headers = headers.append('Access-Control-Allow-Origin', '*');
    headers = headers.append('Access-Control-Allow-Methods', 'GET, POST, PATCH, PUT, DELETE, OPTIONS');
    headers = headers.append('Access-Control-Allow-Headers', 'Origin, Content-Type, X-Auth-Token');
    return this.http.get(this.configService.getEndpointPayoutUrl() + `/report/payoutMonthly/file?partnerId=${localStorage.getItem('liquid_payout_portal_partnerId')}`, {responseType: 'arraybuffer', headers: headers}).pipe(switchMap((response: any) => {
      var blob = new Blob([response], {type: "application/ms-excel"});
      FileSaver.saveAs(blob, 'Month-Report.xlsx');
      return of(EMPTY);
    }))
  }

  uploadReport(reportId: string, fileName: string){
    let headers = new HttpHeaders({
      authorization: 'bearer ' + localStorage.getItem('liquid_payout_portal_id_token')
    });
    headers = headers.append('Access-Control-Allow-Origin', '*');
    headers = headers.append('Access-Control-Allow-Methods', 'GET, POST, PATCH, PUT, DELETE, OPTIONS');
    headers = headers.append('Access-Control-Allow-Headers', 'Origin, Content-Type, X-Auth-Token');
    return this.http.get(this.configService.getEndpointPayoutUrl() + `/report/payout/${reportId}/file`, {responseType: 'arraybuffer', headers: headers}).pipe(switchMap((response: any) => {
      var blob = new Blob([response], {type: "application/ms-excel"});
      FileSaver.saveAs(blob, fileName);
      return of(EMPTY);
    }))
  }
}
