import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UUID } from 'angular2-uuid';

@Component({
  selector: 'app-liquid-custom-input',
  templateUrl: './liquid-custom-input.component.html',
  styleUrls: ['./liquid-custom-input.component.scss']
})
export class LiquidCustomInputComponent implements OnInit {
  @Input() outerId: string;
  @Input() list: any[];
  @Input() label: string = 'name';
  @Input() value: string = 'value';
  @Input() selectedValue: string;
  @Input() type: string;
  @Input() length: string;
  @Input() maxHeight: string;
  @Input() customCssClass: string;
  @Input() placeholder: string = 'Please, select any items';
  @Input() searchBar: boolean = true;
  @Input() multiselect: boolean = false;
  @Input() hidePartnersData: boolean = false;//not used
  @Input() readonly: boolean = false;
  uuid: string;
  dropdownOpened: boolean = false;
  searchFieldValue: string = '';
  //forbidToClose: boolean = false;//not used
  @Output() changed: EventEmitter<string> = new EventEmitter<string>();
  @Output() changedMultiple: EventEmitter<{value: string, selected: boolean}> = new EventEmitter<{value: string, selected: boolean}>();

  constructor() { }

  ngOnInit(): void {
    this.uuid = UUID.UUID();

    this.updateListFromSelectedValues();
    this.updateSelectedValuesString();
  }

  ngOnChanges(){
    this.updateListFromSelectedValues();
  }

  toggleDropdown(){
    if (this.readonly)
      return;
    this.dropdownOpened = !this.dropdownOpened;
  }

  closeDropdown(){
    this.dropdownOpened = false;
  }

  updateListFromSelectedValues(){
    if (!this.multiselect) return;
    this.list.forEach(x => x.selected = this.selectedValue?.includes(x.value));
  }

  updateSelectedValuesString(){
    if (!this.multiselect) return;
    const selectedItems = this.list.filter(x => x.selected).map(x => x[this.label]).join(', ');
    this.selectedValue = selectedItems || this.placeholder;
  }

  selectOption(item, event){
    event.preventDefault();
    event.stopPropagation();
    if (!this.multiselect){
      this.selectItem(item);
    } else {
      this.toggleItem(item);
    }
  }

  selectItem(item){
    this.selectedValue = item[this.label];
    this.changed.emit(item[this.value]);
    //console.log('Selected item', item[this.value]);
    this.dropdownOpened = false;
  }

  toggleItem(item){
    item.selected = !item.selected;
    this.updateSelectedValuesString();
    this.changedMultiple.emit({value: item[this.value], selected: item.selected});
    this.dropdownOpened = true;
  }

  filter(item){
    return this.searchFieldValue === ''
      || item[this.label].toLowerCase().includes(this.searchFieldValue.toLowerCase());
  }

  /**
   * Obsolete function to filter values
   * @param item
   * @returns
   */
  showItem(item){
    let list = [
      'Administrator',
      'Finance User',
      'Implementation User',
      'Super Admin',
    ];

    return (this.filter(item)
      && ((this.hidePartnersData && item.IsCompanyAssociatedOnly) || !list.includes(item.name)));
  }
}
