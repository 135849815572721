import { Component, Input, OnInit } from '@angular/core';

export type SettingsSection = {
  title: string,
  icon: string,
  items: SettingsItem[],
  hide?: boolean,
}
type SettingsItem = {
  title: string,
  description: string,
  link: string,
  hide?: boolean,
}

@Component({
  selector: 'app-settings-menu',
  templateUrl: './settings-menu.component.html',
  styleUrls: ['./settings-menu.component.scss']
})
export class SettingsMenuComponent implements OnInit {

  _sections: SettingsSection[];
  @Input() private sections: SettingsSection[];

  constructor() { }
  ngOnInit(): void { }

  // register changes and filters hidden items or sections
  ngOnChanges(): void {
    if (!this.sections)
      return;
    this._sections = this.sections.map(x => {
      const section = {...x};
      section.items = section.items.filter(item => !item.hide);
      section.hide = section.hide || section.items.length === 0;
      return section;
    }).filter(x => !x.hide);
  }
}
