import { Attribute, Component, EventEmitter, Input, Output, Self, ViewChild } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { UUID } from 'angular2-uuid';

@Component({
  selector: 'app-liquid-form-input',
  templateUrl: './liquid-form-input.component.html',
  styleUrls: ['./liquid-form-input.component.scss'],
})
export class LiquidFormInputComponent implements ControlValueAccessor {
  uuid: string = null;
  control: any = null;
  required: boolean = false;
  @Input() type: string = 'text';
  @Input() value: string;
  @Input() label: string;
  @Input() multiselect: boolean = false;
  @Input() placeholder: string = null;
  @Input() placeholderVerb: string = 'Please add';
  @Input() formName: string;
  @Input() isDisabled = false;
  @Input() showErrors = false;
  @Input() min: number | string = null;
  @Input() maxLength: number = null;
  @Input() customCssClass: string;
  @Input() tooltip: string = null;
  @Input() searchBar: boolean = true;
  //@Input() customError
  private _customError: { name: string, value: string } = null;
  @Input() set customError(value: any) {
    this._customError = value;
    if (this.customError){
      this.errorMessages[this.customError.name] = this.customError.value;
      this.errorMessage = this.customError.value;
    }
  }
  get customError(): any {
    return this._customError;
  }

  @Input() list: [any] = null;
  @Input() selectedValue: string;

  @Input() constrain: string;
  onChange = (value) => {};
  onTouched = () => {};
  @Output() changed = new EventEmitter<any>();
  errorMessage: string;
  errorMessages = {
    required: '',
    pattern: '',
    maxlength: ''
  };
  uppercase: boolean;

  @ViewChild('input', { static: false, read: NgControl }) input: NgControl;

  constructor(
    @Self() public controlDir: NgControl,
    @Attribute('uppercase') private _uppercase,
  ) {
    // if (this.selectedValue){
    //   this.value = this.selectedValue;
    // }
    controlDir.valueAccessor = this;
    // const validators: (Function | Validator)[] = injector.get(NG_VALIDATORS);
    // console.log(this.label, validators);
    this.uppercase = typeof(this._uppercase) === 'string';
  }

  ngOnInit(): void {
    const control = this.controlDir.control;
    if (!control)
      return console.log('error control');
    this.placeholderVerb = this.placeholderVerb + ' ';
    //control.setValidators(Validators.required);
    control.updateValueAndValidity();
    this.control = control;
    this.uuid = UUID.UUID();
    // this.errorMessages.required = `${this.label} is required`;
    // if (this.formName)
    //   this.errorMessages.required += ` for ${this.formName}`;
    // this.errorMessages.pattern = `Wrong format for ${this.label}`;
    // if (this.customError){
    //   this.errorMessages[this.customError.name] = this.customError.value;
    // }
    this.setupErrors();
    this.handleErrors();
    this.getSelectedValue(this.value);
    
    // if (this.input !== null)
    //   this.input?.control?.setValidators(this.control.validator);
  }

  ngAfterViewInit() {
    if (this.control == null || this.input == null)
      return;
    this.input?.control?.setValidators(this.control.validator);
  }

  ngOnChanges(){
    //console.log('change', this.selectedValue);
    this.setupErrors();
    if (this.control) {
      this.handleErrors();
    }
  }

  setupErrors(){
    this.errorMessages.required = `${this.label} is required`;
    if (this.formName)
      this.errorMessages.required += ` for ${this.formName}`;
    this.errorMessages.pattern = `Wrong format for ${this.label}`;
    if (this.customError){
      this.errorMessages[this.customError.name] = this.customError.value;
    }
  }
  
  handleChange(value) {
    this.onTouched();
    this.onChange(value);
    this.changed.emit(value);
    this.control.updateValueAndValidity();
    this.getSelectedValue(value);
    this.handleErrors();
  }

  handleChangeMultiple(item){
    const helper = new Set(this.control.value);
    if (item.selected)
      helper.add(item.value);
    else
      helper.delete(item.value);
    this.control.setValue([...helper]);
    this.handleChange([...helper]);
  }

  handleErrors(){
    this.required = this.control.errors?.required;
    if (this.control.errors){
      const key = Object.keys(this.control.errors)[0];
      if (key === 'maxlength' && !this.errorMessages[key]){
        const maxLength = this.control.errors[key].requiredLength;
        this.errorMessages[key] = `${this.label} should be ${maxLength} characters long`;
      }
      this.errorMessage = this.errorMessages[key] || `Invalid ${this.label}`;
    }
  }

  //get value from form, set value into component
  writeValue(value: string) {
    if (value)
      this.value = value;
    
    this.onChange(this.value);
    //this.changed.emit(this.value);
  }
  getSelectedValue(value){
    if (!this.list)
      return;
    const item = this.list.find(x => x.value == value);
    if (item)
      this.selectedValue = item.name;
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState(isDisabled: boolean) {
    this.isDisabled = isDisabled;
  }
}
