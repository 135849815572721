import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Resolve, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { ConfigService } from '../../shared/services/config/config.service';
import { ReplaySubject, Subscription } from 'rxjs';
import { switchMap, map, share, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router, private configService: ConfigService) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> {
    if (localStorage.getItem('liquid_payout_portal_id_token')) {
      return this.configService.getConfigSetup().pipe(map(result => {
        return true;
      }));
    } else if (!localStorage.getItem('liquid_payout_portal_id_token')){
      return this.configService.getConfigSetup().pipe(map(result => {
        this.router.navigateByUrl('/login');
        return false;
      }));
    }
  }
}
