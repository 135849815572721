import { AfterContentInit, Component, ContentChildren, HostBinding, Input, QueryList } from '@angular/core';

/**
 * Insert slides with <section #slide></section>
 * @param {number} current sets the current index
 */
@Component({
  selector: 'app-slider',
  template: '<ng-content></ng-content>',
  styleUrls: ['./slider.component.scss']
})
export class SliderComponent implements AfterContentInit {
  constructor() { }
  ngOnInit() { }

  private slides: number = 1;
  @ContentChildren("slide") private slidesList!: QueryList<any>;
  @HostBinding('style.--index') private _current: number = 0;
  @Input() set current(value: number){
    this._current = Math.abs(value || 0) % this.slides;
    this.setActiveSlide();
  };

  //heights: number[] = [];
  ngAfterContentInit(): void {
    //this.heights = this.slidesList?.toArray().map(el => el.nativeElement.offsetHeight);

    this.slides = this.slidesList ? this.slidesList.length : 0;
    this.setActiveSlide();
  }
  private setActiveSlide(){
    if (this.slides === 0)
      return;
    this.slidesList?.toArray().forEach((el: {nativeElement: HTMLElement}, index) => {
      el.nativeElement.className = '';
      el.nativeElement.removeAttribute('style');
      if (index != this._current){
        setTimeout(() => {
          el.nativeElement.className = 'disabled';
        }, 600);
      }
    });
    this.slidesList?.get(this._current).nativeElement.setAttribute('style', '--active: 1');
  }
}
