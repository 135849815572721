import { Injectable } from '@angular/core';
import { ApiService } from '../api/api.service';
import { OperationsService } from '../operations/operations.service';
import { switchMap, map, share, catchError } from 'rxjs/operators';
import { Observable, of, EMPTY, Subject, BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';
import { HttpClient, HttpResponse, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { NotifierService } from 'angular-notifier';
import { ConfigService } from '../config/config.service';
import * as FileSaver from 'file-saver';
import { Recipient, SaveDomesticSingleRequest, SaveCrossBorderSingleRequest, UpdateDomesticPaymentInstructionRequest } from 'types/instruction';

@Injectable({
  providedIn: 'any'
})

export class InvoiceService {

  //private host: string = 'https://localhost:5001/api/invoice';
  private getBaseHeaders(){
    const key = this.configService.isProdEnvironment() == true ? 'uYVMrXCNuK4AaSV4e1qXiakDUwoRQgMD7YbcJBSF' : 'wN9ku66WbEA3m5n8xgXDEbacUNYR7R6Fz8Wpwr90';
    let headers = new HttpHeaders({
      'authorization': 'bearer ' + localStorage.getItem('liquid_payout_portal_id_token'),
      'Accept': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': 'GET, POST, PATCH, PUT, DELETE, OPTIONS',
      'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token',
      'Liquid-Api-Key': key,
    });
    return headers;
  }

  constructor(
    private apiService: ApiService,
    private router: Router,
    private http: HttpClient,
    private notifierService: NotifierService,
    private operationsService: OperationsService,
    private configService: ConfigService
  ) {
      // this.configService.getConfigSetup().pipe(map(() => {
      //   this.host = this.configService.getEndpointPayoutUrl();
      // }));
  }

  getCompanyLabelMapping(){
    return this.apiService.get(`/invoice/mapping/${localStorage.getItem('liquid_payout_portal_companyId')}`).pipe(switchMap((response: any) => {
      return of(response);
    }))
  }

  getInvoice(invoiceId: string){
    return this.apiService.get(`/invoice/${invoiceId}`).pipe(switchMap((response: any) => {
      return of(response);
    }))
  }

  getInvoices(companyId: string, queryString: string){
    return this.apiService.get(`/invoice${queryString}&CompanyId=${companyId}`).pipe(switchMap((response: any) => {
      return of(response);
    }))
  }

  getInvoicesToApprove(){
    return this.apiService.get(`/invoice/pending-approval`).pipe(switchMap((response: any) => {
      return of(response);
    }))
  }

  getPaymentDetails(instructionId: string){
    return this.apiService.get(`/invoice/${instructionId}/payment`).pipe(switchMap((response: any) => {
      return of(response);
    }))
  }

  getAvailableFund(companyId: string, paymentReferenceId: string){
    return this.apiService.get(`/transaction/wallet/${companyId}/balance/${paymentReferenceId}`).pipe(switchMap((response: any) => {
      return of(response);
    }))
  }

  uploadFile(file, companyId: string){
    let headers = new HttpHeaders({
      authorization: 'bearer ' + localStorage.getItem('liquid_payout_portal_id_token')
    });
    // headers = headers.append('x-company-id', companyId);
    headers = headers.append('Accept', 'application/json');
    headers = headers.append('Access-Control-Allow-Origin', '*');
    headers = headers.append('Access-Control-Allow-Methods', 'GET, POST, PATCH, PUT, DELETE, OPTIONS');
    headers = headers.append('Access-Control-Allow-Headers', 'Origin, Content-Type, X-Auth-Token');

    let formValue = {
      companyId: companyId
    }
    if (this.operationsService.selectedInstructionToUpdate != ''){
      headers = headers.append('x-original-instruction-id', this.operationsService.selectedInstructionToUpdate);
    }
    // console.log('Headers', headers.get('x-company-id'));
    const formData = new FormData();
    formData.append('instructionFiles', file);
    const json = JSON.stringify(formValue);
    // const blob = new Blob([json], {
    //   type: 'application/json'
    // });
    formData.append('invoiceRequest', json);
    return this.http.post(this.configService.getEndpointPayoutUrl() + '/invoice/upload', formData, {headers: headers}).pipe(share()).pipe(map((response: any) => {
      return response;
    }), catchError(err => {
      console.error('An error occurred:', err);
      throw new Error(err.error?.ResponseStatus?.Description);
    }));
  }

  getApprovals(id: string){
    return this.apiService.get(`/invoice/${id}/approvals`).pipe(switchMap((response: any) => {
      return of(response);
    }))
  }

  setMapping(fieldObject){
    return this.apiService.post(`/invoice/mapping`, fieldObject).pipe(switchMap((response: any) => {
      return of(response);
    }));
  }

  sendManualInvoice(body){
    return this.apiService.post(`/invoice/manual`, body).pipe(switchMap((response: any) => {
      return of(response);
    }));
  }

  addManualInvoice(body, id){
    return this.apiService.post(`/invoice/${id}/payments/add`, body).pipe(switchMap((response: any) => {
      return of(response);
    }));
  }

  // getSinglePaymentRecipients(){
  //   let body = {
  //     partnerId: localStorage.getItem('liquid_payout_portal_partnerId'),
  //     companyId: localStorage.getItem('liquid_payout_portal_companyId')
  //   };
  //   return this.apiService.post(`/invoice/crossborder/single/fetchrecipients`, body).pipe(switchMap((response: any) => {
  //     return of(response);
  //   }));
  // }

  getSinglePaymentRecipients(){
    let headers = this.getBaseHeaders();
    let host = this.configService.getEndpointPayoutUrl();

    let model = {
      partnerId: localStorage.getItem('liquid_payout_portal_partnerId'),
      companyId: localStorage.getItem('liquid_payout_portal_companyId')
    };

    return this.http.post(
      host + '/invoice/crossborder/single/fetchrecipients',
      model,
      { headers }
    ).pipe(share()).pipe(map((response: Recipient[]) => {
      return response;
    }), catchError(error => {
      console.error('An error occurred:', error.error);
      throw new Error(error.error?.ResponseStatus?.Description || error.message);
    }));
  }

  saveSinglePayout(model: SaveDomesticSingleRequest | SaveCrossBorderSingleRequest, files: File[], isCrossBorder: boolean = false){
    if (isCrossBorder) {
      return this.addSingleInvoice(model, files);
    } else {
      return this.addSingleInvoiceDomestic(model, files);
    }
  }

  addSingleInvoice(body, files: File[]){
    let headers = this.getBaseHeaders();
    let host = this.configService.getEndpointPayoutUrl();

    // let model = new FormData();
    // for (const [key, value] of Object.entries(body)) {
    //   // console.log('Key', key);
    //   // console.log('Value', value);
    //   if (key == 'supportingFiles'){
    //     console.log(typeof value);
    //     model.append(key, (value instanceof Blob || typeof value == 'string') ? value : null);
    //   } else {
    //     model.set(key, (value instanceof Blob) ? value : value.toString());
    //   }
    // }
    const model = this.transformToFormData(body);

    files.forEach(element => {
      model.append('supportingFiles', element);
    });

    return this.http.post(
      host + '/invoice/crossborder/single/save',
      model,
      { headers }
    ).pipe(share()).pipe(map((response: any) => {
      return response;
    }), catchError(error => {
      console.error('An error occurred:', error.error);
      throw new Error(error.error?.ResponseStatus?.Description || error.message);
    }));
  }

  addSingleInvoiceDomestic(body, files: File[]){
    let headers = this.getBaseHeaders();
    let host = this.configService.getEndpointPayoutUrl();

    //let model = new FormData();
    // for (const [key, value] of Object.entries(body)) {
    //   console.log('Key', key);
    //   console.log('Value', value);
    //   if (key == 'supportingFiles'){
    //     console.log(typeof value);
    //     model.append(key, (value instanceof Blob || typeof value == 'string') ? value : null);
    //   } else {
    //     model.set(key, (value instanceof Blob) ? value : value.toString());
    //   }
    // }
    const model = this.transformToFormData(body);

    files.forEach(element => {
      model.append('supportingFiles', element);
    });

    return this.http.post(
      host + '/invoice/domestic/single/save',
      model,
      { headers }
    ).pipe(share()).pipe(map((response: any) => {
      return response;
    }), catchError(error => {
      console.error('An error occurred:', error.error);
      throw new Error(error.error?.ResponseStatus?.Description || error.message);
    }));
  }

  deleteInvoice(id){
    return this.apiService.delete(`/invoice/${id}`).pipe(switchMap((response: any) => {
      return of(response);
    }));
  }

  domesticDeleteInvoice(id: string){
    const headers = this.getBaseHeaders();
    const host = this.configService.getEndpointPayoutUrl();

    return this.http.delete(
      host + `/invoice/${id}`,
      { headers, observe: 'response' }
    ).pipe(map((response: any) => {
      if (response.status === 200)
        return response.body;
      throw new Error(response);
    }), catchError(error => {
      throw new Error(error.error || error.message);
    }));
  }

  domesticDeleteRecord(instructionId: string, paymentId: string){
    const headers = this.getBaseHeaders();
    const host = this.configService.getEndpointPayoutUrl();

    return this.http.delete(
      host + `/invoice/${instructionId}/payments/${paymentId}`,
      { headers, observe: 'response' }
    ).pipe(map((response: any) => {
      if (response.status === 200)
        return response.body;
      throw new Error(response);
    }), catchError(error => {
      throw new Error(error.error || error.message);
    }));
  }

  getManualInvoiceFile(fileId, invoiceId){
    let headers = new HttpHeaders({
      authorization: 'bearer ' + localStorage.getItem('liquid_payout_portal_id_token')
    });
    headers = headers.append('Access-Control-Allow-Origin', '*');
    headers = headers.append('Access-Control-Allow-Methods', 'GET, POST, PATCH, PUT, DELETE, OPTIONS');
    headers = headers.append('Access-Control-Allow-Headers', 'Origin, Content-Type, X-Auth-Token');
    return this.http.get(this.configService.getEndpointPayoutUrl() + `/invoice/${fileId}/download`, {responseType: 'arraybuffer', headers: headers}).pipe(switchMap((response: any) => {
      var blob = new Blob([response], {type: 'application/pdf'});
      FileSaver.saveAs(blob, invoiceId);
      return of(EMPTY);
    }))
  }

  deleteMapping(companyId: string){
    return this.apiService.delete(`/invoice/mapping/${companyId}`).pipe(switchMap((response: any) => {
      return of(response);
    }));
  }

  deletePaymentRow(instructionId: string, paymentId: string){
    return this.apiService.delete(`/invoice/${instructionId}/payments/${paymentId}`).pipe(switchMap((response: any) => {
      return of(response);
    }));
  }

  updateApprovementStatus(invoiceId: string, reqBody: any){
    const responseType = 'text';
    const handleException = true;
    return this.apiService.post(`/invoice/${invoiceId}/approvals`, reqBody, responseType, handleException).pipe(switchMap((response: any) => {
      return of(response);
    }, error => {
      console.log(error);
      return error;
    }));
  }

  setApprovalWorkflow(settings){
    return this.apiService.post(`/invoice/approval-workflow`, settings).pipe(switchMap((response: any) => {
      this.notifierService.notify('success','Your Payment Approval Settings is successfully saved');
      return of(response);
    }));
  }

  getApprovalWorkflow(partnerId: string){
    return this.apiService.get(`/invoice/approval-workflow/${partnerId}`).pipe(switchMap((response: any) => {
      return of(response);
    }));
  }

  getNewApprovalWorkflow(){
    return this.apiService.get(`/invoice/approval-workflow/v2/${localStorage.getItem('liquid_payout_portal_partnerId')}/${localStorage.getItem('liquid_payout_portal_companyId')}`).pipe(switchMap((response: any) => {
      return of(response);
    }));
  }

  setNewApprovalWorkflow(workflow){
    return this.apiService.post(`/invoice/approval-workflow/v2`, workflow, 'text').pipe(switchMap((response: any) => {
      return of(response);
    }));
  }

  deleteNewApprovalWorkflow(id, object){
    return this.apiService.post(`/invoice/approval-workflow/v2/delete`, object).pipe(switchMap((response: any) => {
      return of(response);
    }));
  }

  updateNewApprovalWorkflow(item){
    return this.apiService.update(`/invoice/approval-workflow/v2/${item.approvalWorkflowId}`, item).pipe(switchMap((response: any) => {
      return of(response);
    }));
  }

  getExactApprovalWorkflow(partnerId: string, approvalWorkflowId: string){
    return this.apiService.get(`/invoice/approval-workflow/${partnerId}/${approvalWorkflowId}`).pipe(switchMap((response: any) => {
      return of(response);
    }));
  }

  confirmInvoice(confirmObject){
    return this.apiService.post(`/invoice/confirm`, confirmObject).pipe(switchMap((response: any) => {
      if (response){
        // this.notifierService.notify('success','Your invoice was successfully sent');
      }
      return of(response);
    }));
  }

  updateErrorCases(errorCases: UpdateDomesticPaymentInstructionRequest[]){
    return this.apiService.post(`/invoice/payments/update`, errorCases).pipe(switchMap((response: any) => {
      return of(response);
    }))
  }

  getFileFields(instructionId){
    let headers = new HttpHeaders({
      authorization: 'bearer ' + localStorage.getItem('liquid_payout_portal_id_token')
    });
    headers = headers.append('Access-Control-Allow-Origin', '*');
    headers = headers.append('Access-Control-Allow-Methods', 'GET, POST, PATCH, PUT, DELETE, OPTIONS');
    headers = headers.append('Access-Control-Allow-Headers', 'Origin, Content-Type, X-Auth-Token');
    return this.http.get(this.configService.getEndpointPayoutUrl() + `/invoice/${instructionId}/preview/`, {headers: headers}).pipe(map((response: any) => {
      if (localStorage.getItem('liquid_payout_portal_partnerId') === response.partnerId)
        return response;
      throw new Error('Unauthorized');
    }), catchError(err => {
      if (err.error.ResponseStatus.Description){
        this.notifierService.notify('error', err.error.ResponseStatus.Description);
      }
      return err;
    }));
  }

  //new endpoints from June 2024

  uploadInstruction(file, type: string = '', isCrossBorder: boolean = false){
    if (isCrossBorder){
      return this.crossborderUpload(file, type);
    } else {
      return this.domesticUpload(file, localStorage.getItem('liquid_payout_portal_companyId'));
    }
  }

  previewInstruction(instructionId: string, isCrossBorder: boolean = false){
    if (isCrossBorder){
      return this.crossborderPreview(instructionId);
    } else {
      return this.domesticPreview(instructionId);
    }
  }

  editCurrency(instructionId: string, currency: string){
    let model = {
      partnerId: localStorage.getItem('liquid_payout_portal_partnerId'),
      companyId: localStorage.getItem('liquid_payout_portal_companyId'),
      instructionId: instructionId,
      settlementCurrency: currency,
    };
    return this.payoutServicePOST('/invoice/update-settlementcurrency', model);
  }

  cancelInstruction(instructionId: string, isCrossBorder: boolean = false){
    if (isCrossBorder){
      return this.crossborderCancelInstruction(instructionId);
    } else {
      return this.domesticDeleteInvoice(instructionId);
    }
  }

  uploadSupportingFiles(instructionId: string, referenceId: string, files: File[], isCrossBorder: boolean = false){
    if (isCrossBorder) {
      return this.crossborderUploadSupportingFiles(instructionId, referenceId, files);
    } else {
      return this.domesticUploadSupportingFiles(instructionId, referenceId, files);
    }
  }

  editRecord(instructionId: string, payment: any, isCrossBorder: boolean = false){
    if (isCrossBorder){
      return this.crossborderEditRecord(instructionId, payment);
    } else {
      return this.updateErrorCases([payment]);
    }
  }

  deleteRecord(instructionId: string, paymentIds: string[], isCrossBorder: boolean = false){
    if (isCrossBorder){
      return this.crossborderDeleteRecord(instructionId, paymentIds);
    } else {
      return this.domesticDeleteRecord(instructionId, paymentIds[0]);
    }
  }

  domesticUpload(file, companyId: string){
    return this.uploadFile(file, companyId);
  }

  domesticPreview(instructionId: string){
    return this.getFileFields(instructionId);
  }

  crossborderUpload(file: File, type?: string){
    let headers = this.getBaseHeaders();
    let host = this.configService.getEndpointPayoutUrl();

    let model = new FormData();
    model.set('partnerId', localStorage.getItem('liquid_payout_portal_partnerId'));
    model.set('companyId', localStorage.getItem('liquid_payout_portal_companyId'));
    model.set('instructionType', type || 'Invoice');
    model.set('settlementCurrency', 'SGD');
    model.set('file', file);

    return this.http.post(
      host + '/invoice/crossborder/uploadinvoice',
      model,
      { headers }
    ).pipe(share()).pipe(map((response: any) => {
      return response;
    }), catchError(error => {
      console.error('An error occurred:', error.error);
      throw new Error(error.error?.ResponseStatus?.Description || error.message);
    }));
  }
  
  crossborderPreview(id: string){
    let headers = this.getBaseHeaders();
    let host = this.configService.getEndpointPayoutUrl();

    let model = {
      'partnerId': localStorage.getItem('liquid_payout_portal_partnerId'),
      'companyId': localStorage.getItem('liquid_payout_portal_companyId'),
      'instructionType': 'Invoice',
      'instructionId': id,
    };

    return this.http.post(
      host + '/invoice/crossborder/invoicepreview',
      model,
      { headers }
    ).pipe(share()).pipe(map((response: any) => {
      return response;
    }), catchError(error => {
      console.error('An error occurred:', error.error);
      throw new Error(error.error?.ResponseStatus?.Description || error.message);
    }));
  }

  crossborderEditRecord(instructionId: string, paymentItem: any){
    let model = {
      partnerId: localStorage.getItem('liquid_payout_portal_partnerId'),
      companyId: localStorage.getItem('liquid_payout_portal_companyId'),
      instructionId: instructionId,
      paymentItem: paymentItem,
    };
    return this.payoutServicePOST('/invoice/crossborder/updatepaymentdetails', model);
  }

  crossborderDeleteRecord(instructionId: string, paymentIds: string[]){
    let headers = this.getBaseHeaders();
    let host = this.configService.getEndpointPayoutUrl();

    let model = {
      partnerId: localStorage.getItem('liquid_payout_portal_partnerId'),
      companyId: localStorage.getItem('liquid_payout_portal_companyId'),
      instructionId: instructionId,
      paymentRefIds: paymentIds,
    };

    return this.http.post(
      host + '/invoice/crossborder/deletepayment',
      model,
      { headers, observe: 'response' }
    ).pipe(map((response: any) => {
      if (response.status === 200)
        return response.body;
      throw new Error(response);
    }), catchError(error => {
      throw new Error(error.error || error.message);
    }));
  }

  crossborderUploadSupportingFiles(instructionId: string, referenceId: string, files: File[]){
    let model = new FormData();
    model.set('partnerId', localStorage.getItem('liquid_payout_portal_partnerId'));
    model.set('companyId', localStorage.getItem('liquid_payout_portal_companyId'));
    model.set('instructionId', instructionId);
    model.set('referenceId', referenceId);
    files.forEach(file => model.append('files', file));

    return this.payoutServicePOST('/invoice/crossborder/uploadsupportingfiles', model);
  }

  domesticUploadSupportingFiles(instructionId: string, referenceId: string, files: File[]){
    let model = new FormData();
    model.set('partnerId', localStorage.getItem('liquid_payout_portal_partnerId'));
    model.set('companyId', localStorage.getItem('liquid_payout_portal_companyId'));
    model.set('instructionId', instructionId);
    model.set('referenceId', referenceId);
    files.forEach(file => model.append('files', file));

    return this.payoutServicePOST('/invoice/uploadsupportingfiles', model);
  }

  crossborderUploadSupportedFilesDomestic(instructionId: string, referenceId: string, files: File[]){
    let headers = this.getBaseHeaders();
    let host = this.configService.getEndpointPayoutUrl();

    let model = new FormData();
    model.set('partnerId', localStorage.getItem('liquid_payout_portal_partnerId'));
    model.set('companyId', localStorage.getItem('liquid_payout_portal_companyId'));
    model.set('instructionId', instructionId);
    model.set('referenceId', referenceId);
    files.forEach(file => model.append('files', file));

    return this.http.post(
      host + '/invoice/downloadsupportingfiles',
      model,
      { headers }
    ).pipe(share()).pipe(map((response: any) => {
      return response;
    }), catchError(error => {
      console.error('An error occurred:', error.status);
      return error.status;
    }));
  }

  crossborderDownloadSupportedFiles(instructionId: string, referenceId: string, filename: string){
    let model = {
      'partnerId': localStorage.getItem('liquid_payout_portal_partnerId'),
      'companyId': localStorage.getItem('liquid_payout_portal_companyId'),
      'instructionId': instructionId,
      'referenceId': referenceId,
      'fileName': filename,
    };
    return this.payoutServicePOST('/invoice/crossborder/downloadsupportingfiles', model, 'blob');
  }

  crossborderCancelInstruction(instructionId: string){
    let model = {
      partnerId: localStorage.getItem('liquid_payout_portal_partnerId'),
      companyId: localStorage.getItem('liquid_payout_portal_companyId'),
      instructionId: instructionId,
    };
    return this.payoutServicePOST('/invoice/crossborder/cancelinvoice', model);
  }

  crossborderRecipientCountries(){
    let model = { partnerId: localStorage.getItem('liquid_payout_portal_partnerId') };
    return this.payoutServicePOST('/invoice/crossborder/recipientcountries', model);
  }

  getCrossborderTransferPurposeList(){
    let model = { partnerId: localStorage.getItem('liquid_payout_portal_partnerId') };
    return this.payoutServicePOST('/invoice/crossborder/transferpurposes', model);
  }

  payoutServicePOST(path: string, model: object | FormData, responseType?: any){
    let headers = this.getBaseHeaders();
    let host = this.configService.getEndpointPayoutUrl();
    return this.http.post(
      host + path,
      model,
      { headers, responseType: responseType || 'json'  }
    ).pipe(share()).pipe(map((response: any) => {
      return response;
    }), catchError(error => {
      console.error('An error occurred:', error);
      throw new Error(error.error || error.message || error.status);
    }));
  }


  private transformToFormData(object: any): FormData{
    let body = new FormData();
    for (const [key, value] of Object.entries(object)) {
      body.append(key, (value || '').toString());
    }
    return body;
  }

}
