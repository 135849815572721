import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType, Effect } from '@ngrx/effects';
import { of, EMPTY } from 'rxjs';
import { map, exhaustMap, catchError, switchMap, concatMap } from 'rxjs/operators';
import { UserService } from '../../shared/services/user/user.service';
import { PermissionService } from '../../shared/services/permission/permission.service';
import { EnumPermissionsActions, getPermissionsSuccess, getPermissions } from '../actions/permissions.actions';
import * as moment from "moment";
import 'moment-timezone';

@Injectable()
export class PermissionsEffects {
  timeZone: number;
  
  @Effect() getPermissions$ =
  this.actions$.pipe(
    ofType<getPermissions>(EnumPermissionsActions.getPermissions),
    map(action => action.payload),
    concatMap(action =>
      this.userService.getRolesPermissions(action.partnerId, action.role, true).pipe(
        map(request => {
          this.permissionService.updatePermissionSelection(request);
          return new getPermissionsSuccess(request);
        })
      )
    )
  );
  //   switchMap((action) => this.userService.getRolesPermissions(action.partnerId, action.role)),
  //   switchMap((eventHttp: any) => of(new getPermissionsSuccess(eventHttp)))
  // );
  constructor(
    private userService: UserService,
    private permissionService: PermissionService,
    private actions$: Actions
  ) { }
}
