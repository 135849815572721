import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guards/auth/auth-guard.guard';
import { SetupGuard } from './guards/setup/setup-guard.guard';
import { TokenGuard } from './guards/token/token-guard.guard';
import { SignUpComponent } from './auth/sign-up/sign-up.component';

export const routes: Routes = [
  { path: '', canActivate: [AuthGuard], children: [
    { path: '', canActivate: [TokenGuard], loadChildren: () => import('./core/core.module').then(m => m.CoreModule) }
  ]},
  { path: 'login', canActivate: [SetupGuard], loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule) },
  //{ path: 'onboarding-enquiry', canActivate: [SetupGuard], loadChildren: () => import('./onboarding-enquiry/onboarding-enquiry.module').then(m => m.OnboardingEnquiryModule) },
  { path: 'sign-up', canActivate: [SetupGuard], data: {breadcrumbs: false, text: 'Sign Up'}, component: SignUpComponent},
  //{ path: 'sign-up', canActivate: [SetupGuard], loadChildren: () => import('./self-onboarding/self-onboarding.module').then(m => m.SelfOnboardingModule) },
  { path: 'ui-settings', canActivate: [SetupGuard], loadChildren: () => import('./ui-settings/ui-settings.module').then(m => m.UiSettingsModule) },
  { path: 'forgot-password', canActivate: [SetupGuard], loadChildren: () => import('./forgot-password/forgot-password.module').then(m => m.ForgotPasswordModule)},
  { path: 'reset-password', canActivate: [SetupGuard], loadChildren: () => import('./forgot-password/forgot-password.module').then(m => m.ForgotPasswordModule)},
  { path: 'self-onboarding', canActivate: [SetupGuard], loadChildren: () => import('./self-onboarding/self-onboarding.module').then(m => m.SelfOnboardingModule)},
];

@NgModule({
  declarations: [
  ],
  imports: [
    CommonModule,
    RouterModule.forRoot(routes, {
      onSameUrlNavigation: 'reload',
      paramsInheritanceStrategy: 'always',
    })
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule { }
