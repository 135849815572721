import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { WizardService, WizardSteps } from '../../services/wizard/wizard.service';

@Component({
  selector: 'app-wizard-header',
  templateUrl: './wizard-header.component.html',
  styleUrls: ['./wizard-header.component.scss']
})
export class WizardHeaderComponent implements OnInit {

  constructor(private service: WizardService) { }
  @Input() steps: WizardSteps;
  @Output() onStepChange: EventEmitter<number> = new EventEmitter<number>();
  @Output() onEnd: EventEmitter<boolean> = new EventEmitter<boolean>();
  ngOnInit(): void {}
  setStep(index: number){
    this.service.setStep(this.steps, index);
    if (this.steps.end){
      this.onEnd.emit(true);
    } else {
      this.onStepChange.emit(index);
    }
  }
  cancel(){
    this.onEnd.emit(false);
  }
}
