import { Injectable } from '@angular/core';
import { ApiService } from '../api/api.service';
import { OperationsService } from '../operations/operations.service';
import { switchMap, map, share, catchError } from 'rxjs/operators';
import { Observable, of, EMPTY, Subject, BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';
import { HttpClient, HttpResponse, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { NotifierService } from 'angular-notifier';
import { ConfigService } from '../config/config.service';
import { Rate } from 'types/instruction';

@Injectable({
  providedIn: 'any'
})

export class InstructionService {
  
  constructor(
    private apiService: ApiService,
    private router: Router,
    private http: HttpClient,
    private notifierService: NotifierService,
    private operationsService: OperationsService,
    private configService: ConfigService
  ) { }
  
  
  getBankAccounts(companyId: string){
    return this.apiService.get(`/company/${companyId}/bankAccounts`).pipe(switchMap((response: any) => {
      return of(response);
    }))
  }
  
  getApprovalItems(body: any){
    return this.apiService.post(`/payout/instruction/approved/items`, body).pipe(switchMap((response: any) => {
      return of(response);
    }))
  }
  
  getApprovals(id: string){
    return this.apiService.get(`/payout/instruction/${id}/approvals`).pipe(switchMap((response: any) => {
      return of(response);
    }))
  }
  
  getAuditTrail(id: string){
    return this.apiService.get(`/payout/instruction/${id}/audit-trail`).pipe(switchMap((response: any) => {
      return of(response);
    }))
  }
  
  getBankSwiftCodes(){
    return this.apiService.get(`/bank/swiftCodes`).pipe(switchMap((response: any) => {
      return of(response);
    }))
  }
  
  createBankAccount(companyId, bankAccount){
    return this.apiService.post(`/company/${companyId}/bankAccounts`, bankAccount).pipe(switchMap((response: any) => {
      return of(response);
    }))
  }
  
  updateBankAccount(companyId, bankAccount){
    return this.apiService.update(`/company/${companyId}/bankAccounts/${bankAccount.id}`, bankAccount).pipe(switchMap((response: any) => {
      return of(response);
    }))
  }
  
  deleteBankAccount(companyId, bankAccount){
    return this.apiService.delete(`/company/${companyId}/bankAccounts/${bankAccount.id}`).pipe(switchMap((response: any) => {
      return of(response);
    }))
  }
  
  getInstructions(companyId: string, queryString: string){
    return this.apiService.get(`/payout/instructions${queryString}&CompanyId=${companyId}`).pipe(switchMap((response: any) => {
      return of(response);
    }))
  }
  
  getInstruction(instructionId: string){
    return this.apiService.get(`/payout/instruction/${instructionId}`).pipe(switchMap((response: any) => {
      return of(response);
    }))
  }
  
  updateErrorCases(errorCases){
    return this.apiService.post(`/payout/instruction/update`, errorCases).pipe(switchMap((response: any) => {
      return of(response);
    }))
  }
  
  retryInstruction(body){
    return this.apiService.post(`/payout/instruction/edda/retry`, body).pipe(switchMap((response: any) => {
      return of(response);
    }))
  }
  
  deleteInstruction(id){
    return this.apiService.delete(`/payout/instruction/${id}`).pipe(switchMap((response: any) => {
      return of(response);
    }));
  }
  
  getInstructionsToApprove(){
    return this.apiService.get(`/payout/instructions/pending-approval`).pipe(switchMap((response: any) => {
      return of(response);
    }))
  }
  
  getPendingInstructions(companyId: string){
    return this.apiService.get(`/payout/instructions/pending-approval/${companyId}`).pipe(switchMap((response: any) => {
      return of(response);
    }))
  }
  
  getPaymentDetails(fileId: string){
    return this.apiService.get(`/payout/instruction/${fileId}/payment`).pipe(switchMap((response: any) => {
      return of(response);
    }))
  }
  
  deletePaymentRow(instructionId: string, paymentId: string){
    return this.apiService.delete(`/invoice/${instructionId}/payments/${paymentId}`).pipe(switchMap((response: any) => {
      return of(response);
    }));
  }
  
  getAvailableFund(companyId: string, paymentReferenceId: string){
    return this.apiService.get(`/transaction/wallet/${companyId}/balance/${paymentReferenceId}`).pipe(switchMap((response: any) => {
      return of(response);
    }))
  }
  
  uploadFile(file, companyId){
    let headers = new HttpHeaders({
      authorization: 'bearer ' + localStorage.getItem('liquid_payout_portal_id_token')
    });
    headers = headers.append('x-company-id', companyId);
    headers = headers.append('Access-Control-Allow-Origin', '*');
    headers = headers.append('Access-Control-Allow-Methods', 'GET, POST, PATCH, PUT, DELETE, OPTIONS');
    headers = headers.append('Access-Control-Allow-Headers', 'Origin, Content-Type, X-Auth-Token');
    if (this.operationsService.selectedInstructionToUpdate != ''){
      headers = headers.append('x-original-instruction-id', this.operationsService.selectedInstructionToUpdate);
    }
    const formData = new FormData();
    formData.append('instructionFile', file);
    return this.http.post(this.configService.getEndpointPayoutUrl() + '/payout/instruction/upload', formData, {headers: headers}).pipe(share()).pipe(map((response: any) => {
      return response;
    }), catchError(err => {
      console.error('An error occurred:', err.status);
      if (err.error.ResponseStatus && err.error.ResponseStatus.Description){
        this.notifierService.notify('error', err.error.ResponseStatus.Description);
      } else {
        
      }
      
      return err.status;
    }));
  }
  
  setMapping(fieldObject){
    return this.apiService.post(`/payout/instruction/mapping`, fieldObject).pipe(switchMap((response: any) => {
      return of(response);
    }));
  }
  
  deleteMapping(companyId: string){
    return this.apiService.delete(`/payout/instruction/mapping/${companyId}`).pipe(switchMap((response: any) => {
      return of(response);
    }));
  }
  
  updateApprovementStatus(instructionId: string, reqBody: any){
    return this.apiService.post(`/payout/instruction/${instructionId}/approvals`, reqBody).pipe(switchMap((response: any) => {
      return of(response);
    }));
  }
  
  setApprovalWorkflow(settings){
    return this.apiService.post(`/payout/instruction/approval-workflow`, settings).pipe(switchMap((response: any) => {
      this.notifierService.notify('success','Your Payment Approval Settings is successfully saved');
      return of(response);
    }));
  }
  
  getApprovalWorkflow(partnerId: string){
    return this.apiService.get(`/payout/instruction/approval-workflow/${partnerId}`).pipe(switchMap((response: any) => {
      return of(response);
    }));
  }
  
  getNewApprovalWorkflow(){
    return this.apiService.get(`/payout/instruction/approval-workflow/v2/${localStorage.getItem('liquid_payout_portal_partnerId')}/${localStorage.getItem('liquid_payout_portal_companyId')}`).pipe(switchMap((response: any) => {
      return of(response);
    }));
  }
  
  setNewApprovalWorkflow(workflow){
    return this.apiService.post(`/payout/instruction/approval-workflow/v2`, workflow, 'text').pipe(switchMap((response: any) => {
      return of(response);
    }));
  }
  
  deleteNewApprovalWorkflow(id, object){
    return this.apiService.post(`/payout/instruction/approval-workflow/v2/delete`, object).pipe(switchMap((response: any) => {
      return of(response);
    }));
  }
  
  updateNewApprovalWorkflow(item){
    return this.apiService.update(`/payout/instruction/approval-workflow/v2/${item.approvalWorkflowId}`, item).pipe(switchMap((response: any) => {
      return of(response);
    }));
  }
  
  
  getExactApprovalWorkflow(partnerId: string, approvalWorkflowId: string){
    return this.apiService.get(`/payout/instruction/approval-workflow/${partnerId}/${approvalWorkflowId}`).pipe(switchMap((response: any) => {
      return of(response);
    }));
  }
  
  getCurrencyConversionRate(currency1: string, currency2: string){
    let reqBody = {
      baseCurrency: currency1,
      quotedCurrency: currency2,
      partnerId: localStorage.getItem('liquid_payout_portal_partnerId')
    }
    return this.apiService.post(`/currencyconverter/getconversionrate`, reqBody).pipe(switchMap((response: any) => {
      return of(response);
    }))
  }
  
  getCurrencyRates(withBackup: boolean = false){
    const companyId = localStorage.getItem('liquid_payout_portal_companyId');
    return this.apiService.get(`/currencyconverter/getallconversionrates/${companyId}`).pipe(switchMap((response: Array<Rate>) => {
      return of(response);
    }), catchError((error: Error) => {
      if (withBackup === false)
        throw error;
      //console.log({error});
      const SGD = 'SGD' as const;
      const rate: Rate = {
        baseCurrency: SGD,
        quotedCurrency: SGD,
        rate: 1,
        provider: 'MasterCard',
      };
      return of([rate]);
    }));
  }

  getPreferredCurrencyPair(rates: Rate[], baseCurrency: string = 'SGD', quotedCurrency: string){
    const availableRates = rates.filter(x => x.baseCurrency == baseCurrency && x.quotedCurrency == quotedCurrency);
    const preferredRate = availableRates?.find(x => x.provider === 'MasterCard') ?? availableRates.find(x => x.provider === 'DeutscheBank');
    if (preferredRate)
      return preferredRate;
    throw new Error('Currency pair not found');
  }

  getPreferredRate(rates: Rate[], baseCurrency: string = 'SGD', quotedCurrency: string){
    return Number(this.getPreferredCurrencyPair(rates, baseCurrency, quotedCurrency).rate);
  }
  
  getCurrencyConversionRate2(from: string, to: string){
    const query = new URLSearchParams({
      "BaseCurrency": from,
      "QuotedCurrency": to,
      "PartnerId": localStorage.getItem('liquid_payout_portal_partnerId')
    });
    return this.apiService.get(`/CurrencyConverter/getconversionrate?${query.toString()}`).pipe(switchMap((response: any) => {
      return of(response);
    }))
  }
  
  confirmInstruction(confirmObject){
    return this.apiService.post(`/payout/instruction/confirm`, confirmObject).pipe(switchMap((response: any) => {
      if (response){
        // this.notifierService.notify('success','Your instruction was successfully sent');
      }
      return of(response);
    }));
  }
  
  getFileFields(instructionId){
    let headers = new HttpHeaders({
      authorization: 'bearer ' + localStorage.getItem('liquid_payout_portal_id_token')
    });
    headers = headers.append('Access-Control-Allow-Origin', '*');
    headers = headers.append('Access-Control-Allow-Methods', 'GET, POST, PATCH, PUT, DELETE, OPTIONS');
    headers = headers.append('Access-Control-Allow-Headers', 'Origin, Content-Type, X-Auth-Token');
    return this.http.get(this.configService.getEndpointPayoutUrl() + `/payout/instruction/${instructionId}/preview`, {headers: headers}).pipe(map((response: any) => {
      if (localStorage.getItem('liquid_payout_portal_partnerId') === response.partnerId)
        return response;
      throw new Error('Unauthorized');
    }), catchError(err => {
      if (err.error.ResponseStatus.Description){
        this.notifierService.notify('error', err.error.ResponseStatus.Description);
      }
      return err;
    }));
  }
  
  getInstructionDetails(instructionId, queryString){
    let headers = new HttpHeaders({
      authorization: 'bearer ' + localStorage.getItem('liquid_payout_portal_id_token')
    });
    headers = headers.append('Access-Control-Allow-Origin', '*');
    headers = headers.append('Access-Control-Allow-Methods', 'GET, POST, PATCH, PUT, DELETE, OPTIONS');
    headers = headers.append('Access-Control-Allow-Headers', 'Origin, Content-Type, X-Auth-Token');
    const url = this.configService.getEndpointPayoutUrl() + `/payout/instruction/${instructionId}/details${queryString}`;
    return this.http.get(url, {headers: headers}).pipe(map((response: any) => {
      if (localStorage.getItem('liquid_payout_portal_partnerId') === response.partnerId)
        return response;
      throw new Error('Unauthorized');
    }), catchError(ex => {
      if (ex.status === 500){
        this.notifierService.notify('error', 'There was an error with your request. Please try again later.');
      } else if (ex.error.ResponseStatus.Description){
        this.notifierService.notify('error', ex.error.ResponseStatus.Description);
      }
      throw ex;
    }));
  }
  
  getFileFieldsReview(instructionId){
    return this.getInstructionDetails(instructionId, '');
    let headers = new HttpHeaders({
      authorization: 'bearer ' + localStorage.getItem('liquid_payout_portal_id_token')
    });
    headers = headers.append('Access-Control-Allow-Origin', '*');
    headers = headers.append('Access-Control-Allow-Methods', 'GET, POST, PATCH, PUT, DELETE, OPTIONS');
    headers = headers.append('Access-Control-Allow-Headers', 'Origin, Content-Type, X-Auth-Token');
    return this.http.get(this.configService.getEndpointPayoutUrl() + `/payout/instruction/${instructionId}/details`, {headers: headers}).pipe(map((response: any) => {
      return response;
    }), catchError(err => {
      return err;
    }));
  }

}
