import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss']
})
export class CheckboxComponent implements OnInit {
  @Input() checked!: boolean;
  @Output() toggled = new EventEmitter<boolean>();
  constructor() { }

  ngOnInit(): void { }

  toggle(){
    this.checked = !this.checked;
    this.toggled.emit(this.checked);
  }
}
