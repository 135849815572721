import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-blank',
  templateUrl: './blank.component.html',
  styleUrls: ['./blank.component.scss']
})
export class BlankComponent implements OnInit {

  constructor(private activatedRoute: ActivatedRoute) { }
  message: string;
  ngOnInit(): void {
    this.message = this.activatedRoute.snapshot.data.message;
  }

}
