import { Attribute, Component, Input, OnInit } from '@angular/core';
import { WizardService, WizardSteps } from '../../services/wizard/wizard.service';

@Component({
  selector: 'app-wizard-steps',
  templateUrl: './wizard-steps.component.html',
  styleUrls: ['./wizard-steps.component.scss']
})
export class WizardStepsComponent implements OnInit {

  readonly: boolean = false;
  @Input() steps: WizardSteps;
  constructor(
    private service: WizardService,
    @Attribute('readonly') private _readonly,
  ) {
    this.readonly = typeof(this._readonly) === 'string';
  }

  ngOnInit(): void {
    if (!this.steps.current)
      this.steps.current = 0;
  }

  setStep(step: number){
    this.service.setStep(this.steps, step);
  }
}
