<label class="otp" (paste)="onPaste($event)">
  <input type="text" inputmode="numeric" pattern="[0-9]" maxlength="1" autocomplete="off"
    #input1 [(ngModel)]="otp1" (input)="onInput($event, 1)">
  <input type="text" inputmode="numeric" pattern="[0-9]" maxlength="1" autocomplete="off"
    #input2 [(ngModel)]="otp2" (input)="onInput($event, 2)">
  <input type="text" inputmode="numeric" pattern="[0-9]" maxlength="1" autocomplete="off"
    #input3 [(ngModel)]="otp3" (input)="onInput($event, 3)">
  <input type="text" inputmode="numeric" pattern="[0-9]" maxlength="1" autocomplete="off"
    #input4 [(ngModel)]="otp4" (input)="onInput($event, 4)">
  <input type="text" inputmode="numeric" pattern="[0-9]" maxlength="1" autocomplete="off"
    #input5 [(ngModel)]="otp5" (input)="onInput($event, 5)">
  <input type="text" inputmode="numeric" pattern="[0-9]" maxlength="1" autocomplete="off"
    #input6 [(ngModel)]="otp6" (input)="onInput($event, 6)">
</label>
