import { EnumPartnersActions } from '../actions/partners.actions';
import { PartnersActions } from '../actions/partners.actions';
import { initialPartnersState} from '../state/partners.state';

export function partnersReducer(
  state = initialPartnersState,
  action: PartnersActions
) {
  switch (action.type) {
    case EnumPartnersActions.getPartnersSuccess:
      return {
        ...state,
        partners: action.payload.data
      };
    default:
      return state;
  }
}
