<article>
  <header>
    <span>{{currencyName}}</span>
    <img src="./assets/flags/{{countryFlag}}.svg"/>
    <span class="main">{{currency}} {{availableBalance | number: '1.2-2'}}</span>
  </header>
  <footer>
    <span>Earmarked Balance</span>
    <span class="main">{{earmarkedBalance | number: '1.2-2'}}</span>
  </footer>
</article>