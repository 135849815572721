import { Component, OnInit, Output, ViewChild, EventEmitter, Input, ElementRef } from '@angular/core';
import { FileHelperService } from '../../services/file-helper/file-helper.service';
import { NotifierService } from 'angular-notifier';
import { UploadFile, UploadInput } from 'ngx-uploader';

export type FileObject = UploadFile & {
  imageFile: string,
  formattedSize: string,
}

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss']
})
export class FileUploadComponent implements OnInit {

  @Input() maxSize: number = 10;
  maxSizeFormatted: string;
  @Input() extensions: string = '.csv,.xls,.xlsx';
  extensionsFormatted: string;

  @Input() file: FileObject = null;
  uuid: string = crypto.randomUUID();
  @ViewChild("fileInput") fileInput: ElementRef;
  @Output() changed: EventEmitter<FileObject> = new EventEmitter<FileObject>();
  imageFiles = this.fileHelperService.imageFiles;
  imageFile: string = this.imageFiles.upload;

  constructor(
    private fileHelperService: FileHelperService,
    private notifierService: NotifierService,
  ) { }
  ngOnInit(): void {
    this.maxSizeFormatted = this.fileHelperService.formatBytes(this.maxSize * 1024 * 1024);
    const extensions = this.extensions.split(',');
    const lastExtension = extensions.pop();
    this.extensionsFormatted = [extensions.join(', '), lastExtension]
      .filter(x => !!x)
      .join(' or ').toLowerCase();
    if (this.file?.imageFile)
      this.imageFile = this.file.imageFile;
  }

  browse(event){
    event.preventDefault();
    event.stopPropagation();
    this.fileInput.nativeElement.click();
  }

  onUpload(event: UploadInput){
    if (!event.file) return;

    if (!this.extensions.split(',').some(x => event.file.name.endsWith(x))){
      this.notifierService.notify('error', `Please upload only a ${this.extensionsFormatted} file.`);
      this.fileInput.nativeElement.value = null;
      return;
    }

    if (this.maxSize * 1024 * 1024 < event.file.size){
      this.notifierService.notify('error', `The uploaded file exceeds the maximum file size (${this.maxSizeFormatted}).`);
      this.fileInput.nativeElement.value = null;
      return;
    }
    
    // console.log(event);
    this.file = {
      ...event.file,
      formattedSize: this.fileHelperService.formatBytes(event.file.size),
      imageFile: this.fileHelperService.getFileIcon(event.file.name)
    };
    this.imageFile = this.file.imageFile;
    this.changed.emit(this.file);
    this.fileInput.nativeElement.value = null;
  }

  remove(event){
    // event.preventDefault();
    // event.stopPropagation();
    this.file = null;
    this.changed.emit(null);
    this.imageFile = this.imageFiles.upload;
  }

}
