<p class="error" *ngIf="error && isMultiCurrency">
  Rates are not available. Please try again later.
</p>
<p class="error" *ngIf="error && !isMultiCurrency">
  Rates are not supported for this company.
</p>
<div class="widget" *ngIf="ready && error === false"
  [class.inline]="inline"
  [class.readonly]="readonly"
  [class.withSwitch]="withSwitch">
  <p class="rate">1 {{ baseCurrency }} = {{ rate | number: '1.4-4' }} {{ quotedCurrency }}</p>
  <div class="currency-input">
    <input
      class="liquid-payout-input currency-input-left"
      type="text"
      placeholder="Amount"
      [(ngModel)]="baseAmount"
      (keyup)="calc()"
      appTwoDigitDecimaNumber/>
    <app-liquid-custom-input customCssClass="currency-input-right"
      [readonly]="readonly"
      [searchBar]="false"
      (changed)="setCurrency($event, true)"
      [selectedValue]="baseCurrency"
      [list]="baseList"
      *ngIf="ready">
    </app-liquid-custom-input>
    <img src="./assets/flags/{{baseCountry}}.svg"/>
  </div>
  <button *ngIf="withSwitch" (click)="switch()" type="button" class="switch" title="Switch currencies" aria-label="Switch currencies">
    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <use href="#switch" />
    </svg>
    <span>Switch currencies</span>
  </button>
  <div class="currency-input">
    <input
      class="liquid-payout-input currency-input-left"
      type="number"
      placeholder="{{ quotedAmount }}"
      readonly/>
    <app-liquid-custom-input customCssClass="currency-input-right"
      [readonly]="readonly"
      [searchBar]="false"
      (changed)="setCurrency($event)"
      [selectedValue]="quotedCurrency"
      [list]="quotedList"
      *ngIf="ready">
    </app-liquid-custom-input>
    <img src="./assets/flags/{{quotedCountry}}.svg"/>
  </div>
  <p>Note: The FX rates are indicative only and will be valid from 1hr to 1 day based on the currency pair.</p>
</div>

<svg style="display: none" width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
  <symbol id="switch">
    <path d="M18.435 13.051L0.686908 12.8233C0.574691 12.8219 0.481606 12.9205 0.480052 13.0424L0.458869 14.7056C0.457316 14.8275 0.547859 14.9285 0.660076 14.9299L16.095 15.128L12.3502 20.1533C12.2438 20.2961 12.3354 20.5136 12.5063 20.5158L14.3553 20.5395C14.4803 20.5411 14.5984 20.4816 14.6788 20.3745L19.0595 14.4951C19.4877 13.9184 19.1186 13.0597 18.435 13.051V13.051ZM19.133 6.51715L3.69807 6.31914L7.44289 1.29375C7.54929 1.15095 7.45768 0.933534 7.2868 0.931342L5.43777 0.907621C5.3128 0.906018 5.19471 0.965495 5.11427 1.07259L0.73363 6.95202C0.305402 7.52872 0.674536 8.38735 1.35549 8.39608L19.1062 8.6238C19.2184 8.62524 19.3115 8.52663 19.313 8.40467L19.3342 6.74152C19.3358 6.61956 19.2452 6.51859 19.133 6.51715Z"/>
  </symbol>
</svg>