import { Action, createAction, props } from '@ngrx/store';

export enum EnumPermissionsActions {
  getPermissions = '[Permission] get Permissions',
  getPermissionsSuccess = '[Permission] get Permissions Success'
}

export class getPermissions implements Action {
  public readonly type = EnumPermissionsActions.getPermissions;
  constructor(public payload: any){}
}

export class getPermissionsSuccess implements Action {
  public readonly type = EnumPermissionsActions.getPermissionsSuccess;
  constructor(public payload: any){}
}

export type PermissionsActions = getPermissions | getPermissionsSuccess;
