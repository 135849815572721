import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-month-input',
  templateUrl: './month-input.component.html',
  styleUrls: ['./month-input.component.scss']
})
export class MonthInputComponent implements OnInit {
  @Input() shown: boolean = false;

  selectedYear: number = 2021;
  selectedMonth: number = 0;
  currentYear: number = 2021;

  @Output() changed: EventEmitter<any> = new EventEmitter<any>();
  @Output() closed: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor() { }

  ngOnInit(): void {
    this.selectedYear = new Date().getFullYear();
    this.currentYear = new Date().getFullYear();
    this.selectedMonth = new Date().getMonth() + 1;
  }

  setMonth(number){
    this.currentYear = this.selectedYear;
    this.selectedMonth = number;
    this.changed.emit({
      month: this.selectedMonth,
      year: this.selectedYear
    })
  }

  closeInput(event){
    if (event.target.id != 'month-input'){
      this.closed.emit(true);
    }
  }

  movePrevYear(){
    this.selectedYear = this.selectedYear - 1;
  }

  moveNextYear(){
    this.selectedYear = this.selectedYear + 1;
  }

}
