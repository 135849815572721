import { EnumNotificationsActions } from '../actions/notifications.actions';
import { NotificationsActions } from '../actions/notifications.actions';
import { initialNotificationsState } from '../state/notifications.state';

export function notificationsReducer(
  state = initialNotificationsState,
  action: NotificationsActions
) {
  switch (action.type) {
    case EnumNotificationsActions.getNotificationsSuccess:
      return {
        ...state,
        notifications: action.payload
      };
    default:
      return state;
  }
}
