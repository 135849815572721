import { Injectable } from '@angular/core';
import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class ValidationService {

  constructor( ) { }
  
  customValidator<T>(validator: (value: string | T, options?: any) => boolean, name: string, options: any = null): ValidatorFn {
    return (control: AbstractControl) : ValidationErrors | null => {
        const value = control.value;
        if (!value) { return null; }

        const isValid = validator(value, options);
        //console.log(value, isValid)
        return !isValid ? {[name]: true} : null;
    }
  }

  validateList(value: string, list: string[]){
    return list.includes(value);
  }

  validateUen(uen: string): boolean {
    const debug = false;
    const entityTypeIndicator = [
        'LP', 'LL', 'FC', 'PF', 'RF', 'MQ', 'MM', 'NB', 'CC', 'CS', 'MB', 'FM', 'GS', 'GA',
        'GB', 'DP', 'CP', 'NR', 'CM', 'CD', 'MD', 'HS', 'VH', 'CH', 'MH', 'CL', 'XL', 'CX',
        'RP', 'TU', 'TC', 'FB', 'FN', 'PA', 'PB', 'SS', 'MC', 'SM'
    ];

    // check that uen is not empty
    if (!uen || String(uen) === '') {
        if (debug) { console.log('UEN is empty'); }
        return false;
    }

    // check if uen is 9 or 10 digits
    if (uen.length < 9 || uen.length > 10) {
        if (debug) { console.log('UEN is not 9 or 10 digits'); }
        return false;
    }

    uen = uen.toUpperCase();
    const characters: Array<any> = uen.split('');

    // (A) Businesses registered with ACRA
    if (characters.length === 9) {
        // check that last character is a letter
        if (!isNaN(characters[characters.length - 1])) {
            if (debug) { console.log('(A) last character is not an alphabet'); }
            return false;
        }

        for (let i = 0; i < characters.length - 1; i++) {
            // check that first 8 letters are all numbers
            if (isNaN(characters[i])) {
                if (debug) { console.log('(A) there are non-numbers in 1st to 8th letters'); }
                return false;
            }
        }

        // (A) Businesses registered with ACRA (SUCCESS)
        if (debug) { console.log('valid (A) Businesses registered with ACRA'); }
        return true;
    }
    else if (characters.length === 10) {
        // check that last character is a letter
        if (!isNaN(characters[characters.length - 1])) {
            if (debug) { console.log('(B)(C) last character is not an alphabet'); }
            return false;
        }

        // (B) Local companies registered with ACRA
        if (!isNaN(characters[0]) && !isNaN(characters[1]) && !isNaN(characters[2]) && !isNaN(characters[3])) {
            // check that 5th to 9th letters are all numbers
            if (!isNaN(characters[4]) && !isNaN(characters[5]) && !isNaN(characters[6]) &&
                !isNaN(characters[7]) && !isNaN(characters[8])) {
                // (B) Local companies registered with ACRA (SUCCESS)
                if (debug) { console.log('valid (B) Local companies registered with ACRA'); }
                return true;
            } else {
                if (debug) { console.log('(B) there are non-numbers in 5th to 9th letters'); }
                return false;
            }
        }
        // (C) All other entities which will be issued new UEN
        else {
            // check that 1st letter is either T or S or R
            if (characters[0] !== 'T' && characters[0] !== 'S' && characters[0] !== 'R') {
                if (debug) { console.log('(C) 1st letter is incorrect'); }
                return false;
            }

            // check that 2nd and 3rd letters are numbers only
            if (isNaN(characters[1]) || isNaN(characters[2])) {
                if (debug) { console.log('(C) 2nd and 3rd letter is incorrect'); }
                return false;
            }

            // check that 4th letter is an alphabet
            if (!isNaN(characters[3])) {
                if (debug) { console.log('(C) 4th letter is not an alphabet'); }
                return false;
            }

            // check entity-type indicator
            let entityTypeMatch = false,
                entityType = String(characters[3]) + String(characters[4]);
            for (let i = 0; i < entityTypeIndicator.length; i++) {
                if (String(entityTypeIndicator[i]) === String(entityType)) {
                    entityTypeMatch = true;
                }
            }
            if (!entityTypeMatch) {
                if (debug) { console.log('(C) entity-type indicator is invalid'); }
                return false;
            }

            // check that 6th to 9th letters are numbers only
            if (isNaN(characters[5]) || isNaN(characters[6]) || isNaN(characters[7]) || isNaN(characters[8])) {
                if (debug) { console.log('(C) 2nd and 3rd letter is incorrect'); }
                return false;
            }

            // (C) All other entities which will be issued new UEN (SUCCESS)
            if (debug) { console.log('valid (C) All other entities which will be issued new UEN'); }
            return true;
        }
    }

    return false;
  }
}
