import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType, Effect } from '@ngrx/effects';
import { of, EMPTY } from 'rxjs';
import { map, exhaustMap, catchError, switchMap, concatMap } from 'rxjs/operators';
import { UserService } from '../../shared/services/user/user.service';
import { EnumRolesActions, getRolesSuccess, getRoles, getDefaultRoles, getDefaultRolesSuccess } from '../actions/roles.actions';
// import * as notificationsActions from '../actions';
import * as moment from "moment";
import 'moment-timezone';

@Injectable()
export class RolesEffects {
    timeZone: number;

    @Effect() getRoles$ =
    this.actions$.pipe(
      ofType<getRoles>(EnumRolesActions.getRoles),
      switchMap((action) => this.userService.getRoles(action.payload['partnerId'])),
      switchMap((eventHttp: any) => of(new getRolesSuccess(eventHttp)))
    );

    @Effect() getDefaultRoles$ =
    this.actions$.pipe(
      ofType<getRoles>(EnumRolesActions.getDefaultRoles),
      switchMap((action) => this.userService.getRolesDefault()),
      switchMap((eventHttp: any) => of(new getDefaultRolesSuccess(eventHttp)))
    );

  constructor(
    private userService: UserService,
    private actions$: Actions
  ) {

  }
}
