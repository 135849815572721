import { Action, createAction, props } from '@ngrx/store';

export enum EnumApprovalWorkflowActions {
  getInstructionApprovalWorkflow = '[ApprovalWorkflow] get Instruction Approval Workflow',
  getInstructionApprovalWorkflowSuccess = '[ApprovalWorkflow] get Instruction Approval Workflow Success',
  getInvoiceApprovalWorkflow = '[ApprovalWorkflow] get Invoice Approval Workflow',
  getInvoiceApprovalWorkflowSuccess = '[ApprovalWorkflow] get Invoice Approval Workflow Success'
}

export class getInstructionApprovalWorkflow implements Action {
  public readonly type = EnumApprovalWorkflowActions.getInstructionApprovalWorkflow;
  constructor(public payload: any){}
}

export class getInstructionApprovalWorkflowSuccess implements Action {
  public readonly type = EnumApprovalWorkflowActions.getInstructionApprovalWorkflowSuccess;
  constructor(public payload: any){}
}

export class getInvoiceApprovalWorkflow implements Action {
  public readonly type = EnumApprovalWorkflowActions.getInvoiceApprovalWorkflow;
  constructor(public payload: any){}
}

export class getInvoiceApprovalWorkflowSuccess implements Action {
  public readonly type = EnumApprovalWorkflowActions.getInvoiceApprovalWorkflowSuccess;
  constructor(public payload: any){}
}

export type ApprovalWorkflowActions = getInstructionApprovalWorkflow | getInstructionApprovalWorkflowSuccess | getInvoiceApprovalWorkflow | getInvoiceApprovalWorkflowSuccess;
