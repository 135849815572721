import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { notificationsReducer } from './store/reducers/notifications.reducer';
import { appReducer } from './store/reducers/app.reducer';
import { partnersReducer } from './store/reducers/partners.reducer';
import { companiesReducer } from './store/reducers/companies.reducer';

import { NotificationsEffects } from './store/effects/notifications.effects';
import { PermissionsEffects } from './store/effects/permissions.effects';
import { RolesEffects } from './store/effects/roles.effects';
import { CompaniesEffects } from './store/effects/companies.effects';
import { PartnersEffects } from './store/effects/partners.effects';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule } from './shared/shared.module';
import { CompanyService } from './shared/services/company/company.service';
import { PermissionService } from './shared/services/permission/permission.service';
import { ConfigService } from './shared/services/config/config.service';
import { InstructionService } from './shared/services/instruction/instruction.service';
import { InvoiceService } from './shared/services/invoice/invoice.service';

import { ApiService } from './shared/services/api/api.service';

import { OperationsService } from './shared/services/operations/operations.service';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { NgxUiLoaderModule, NgxUiLoaderHttpModule, NgxUiLoaderConfig, SPINNER, POSITION, PB_DIRECTION } from "ngx-ui-loader";
//import { PdfViewerModule } from 'ng2-pdf-viewer';

import { NotifierService, NotifierModule  } from 'angular-notifier';
import { DecimalPipe } from '@angular/common';
import { BlankComponent } from './blank/blank.component';
import { NameValuePair } from 'types/common';


declare global {
  interface Array<T> {
    toUnique(): Array<T>;
    toNameValue(): Array<NameValuePair>;
    /** Returns a sorted array by a property of the objects in that array */
    sortByProperty(property?: string): Array<T>;
  }
}
export function deepClone<T>(object: T): T {
  return JSON.parse(JSON.stringify(object));
}
if (!Array.prototype.toUnique){
  Array.prototype.toUnique = function<T>(this: T[]){
    return [...(new Set(this))];
  }
}
if (!Array.prototype.toNameValue){
  Array.prototype.toNameValue = function<T>(this: T[]){
    return this.map(x => ({name: x, value: x}));
  }
}
if (!Array.prototype.sortByProperty){
  Array.prototype.sortByProperty = function<T>(this: T[], property: string = 'name'): T[]{
    return this.sort((a, b) => {
      const valueA = a[property].toLowerCase();
      const valueB = b[property].toLowerCase();
      if (valueA < valueB) return -1;
      if (valueA > valueB) return 1;
      return 0;
    });
  }
}

const ngxUiLoaderConfig: NgxUiLoaderConfig = {
  fgsColor: "#017ac2",
  bgsPosition: POSITION.bottomCenter,
  hasProgressBar: false,
  bgsSize: 40,
  fgsType: SPINNER.ballScaleMultiple, // background spinner type
  pbThickness: 5, // progress bar thickness
  overlayColor: 'rgb(242 243 246 / 35%)',
  minTime: 100,
  delay: 0
};

@NgModule({
  declarations: [
    AppComponent,
    BlankComponent
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    RouterModule,
    HttpClientModule,
    StoreModule.forRoot(appReducer),
    EffectsModule.forRoot([NotificationsEffects, PermissionsEffects, RolesEffects, PartnersEffects, CompaniesEffects]),
    SharedModule,
    NotifierModule,
    NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
    NgxUiLoaderHttpModule.forRoot({
      showForeground: true,
      excludeRegexp : ['/payout/instructions/pending-approval', '/invoice/pending-approval']
     }),
    //PdfViewerModule,
  ],
  exports: [
  ],
  providers: [
    CompanyService,
    ConfigService,
    NotifierService,
    InstructionService,
    InvoiceService,
    ApiService,
    OperationsService,
    DecimalPipe,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
