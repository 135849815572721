import { Role } from '../../models/role';

export interface RolesState {
  roles: Role[],
  defaultRoles: Role[]
}

export const initialRolesState: RolesState = {
  roles: [],
  defaultRoles: []
};
