import { EnumApprovalWorkflowActions } from '../actions/approval-workflow.actions';
import { ApprovalWorkflowActions } from '../actions/approval-workflow.actions';
import { initialApprovalWorkflowState} from '../state/approval-workflow.state';

export function approvalWorkflowReducer(
  state = initialApprovalWorkflowState,
  action: ApprovalWorkflowActions
) {
  switch (action.type) {
    case EnumApprovalWorkflowActions.getInstructionApprovalWorkflowSuccess:
      return {
        ...state,
        instructionApprovalWorkflow: action.payload
      };
    case EnumApprovalWorkflowActions.getInvoiceApprovalWorkflowSuccess:
      return {
        ...state,
        invoiceApprovalWorkflow: action.payload
      };
    default:
      return state;
  }
}
