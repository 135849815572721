import { Injectable } from '@angular/core';
import { ConfigService } from '../config/config.service';

@Injectable({
  providedIn: 'root'
})
export class MyInfoService {
  
  constructor(
    private configService: ConfigService
  ) { }
  
  getMyInfoBizURL(id: string, lead?: string){
    const isPROD = this.configService.isProdEnvironment();
    const environment = isPROD ? 'PROD' : 'STG';
    const apiBase = isPROD ? '' : 'sandbox.';

    const client_id = `${environment}-201504727K-LIQUIDGROUP-MIB-CORPONBOARD`;
    const attributes = 'uinfin,name,email,basic-profile,previous-names,addresses,appointments,shareholders';
    const purpose = 'LiquidNow - Corporate Payment Platform';
    const authApiUrl = `https://${apiBase}api.myinfo.gov.sg/biz/v2/authorise`;
    const redirect_uri = isPROD
      ? 'https://corporate.liquidpaygroup.com/self-onboarding'
      : 'https://test-payoutportal.liquidpay.com/self-onboarding';
    
    const state = {id: id || 'new', lead};
    
    const authoriseUrl = new URL(authApiUrl);
    authoriseUrl.searchParams.append('client_id', client_id);
    authoriseUrl.searchParams.append('attributes', attributes);
    authoriseUrl.searchParams.append('purpose', purpose);
    authoriseUrl.searchParams.append('state', btoa(JSON.stringify(state)));
    authoriseUrl.searchParams.append('redirect_uri', redirect_uri);
    
    return authoriseUrl.href;
  }
}
