import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { ConfigService } from '../config/config.service';

type ServiceOptions = {
  CurrencyCode?: string,
  From?: string,
  To?: string
};
@Injectable({
  providedIn: 'root'
})
export class AccountService {

  private serviceRoot: string;
  private headers: HttpHeaders = new HttpHeaders({
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET, POST, PATCH, PUT, DELETE, OPTIONS',
    'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token',
  });

  constructor(
    private http: HttpClient,
    private configService: ConfigService,
  ) {
    this.serviceRoot = this.configService.getEndpointPayoutUrl();
  }

  getStatement(options?: ServiceOptions){
    const params = new URLSearchParams({
      CompanyId: localStorage.getItem('liquid_payout_portal_companyId'),
      CurrencyCode: options?.CurrencyCode || '',
      From: options?.From || '',
      To: options?.To || '',
    });
    return this.get('/payout/accountStatement/', params);
  }

  downloadStatement(options?: ServiceOptions){
    const params = new URLSearchParams({
      CompanyId: localStorage.getItem('liquid_payout_portal_companyId'),
      CurrencyCode: options?.CurrencyCode || '',
      From: options?.From || '',
      To: options?.To || '',
    });
    return this.download('/payout/accountStatementdownload/', params);
  }

  getStatementCurrencies(){
    const companyId = localStorage.getItem('liquid_payout_portal_companyId');
    return this.get(`/Company/${companyId}/currencyList`);
  }

  private get(path: string, searchParams?: URLSearchParams, responseType?: string){
    const url = new URL(this.serviceRoot + path);
    if (searchParams){
      Array.from(searchParams as any).forEach(([key, value]) => {
        if (value) {
          url.searchParams.append(key, value);
        }
      })
    }

    this.headers = this.headers.set('authorization', 'bearer ' + localStorage.getItem('liquid_payout_portal_id_token'));
    const options = { headers: this.headers, responseType: responseType as any || undefined };

    return this.http.get(
      url.toString(),
      options,
    ).pipe(
      map((response: any) => { return response }),
      catchError(err => { return err })
    );
  }

  private download(path: string, searchParams?: URLSearchParams){
    return this.get(path, searchParams, 'blob');
  }
  
}
