import { Injectable } from '@angular/core';

export type WizardStep = {
  title: string,
  valid?: boolean,
  dirty?: boolean,
  section?: any,
};
export type WizardSteps = WizardStep[] & {
  current?: number,
  end?: boolean,
};

@Injectable({
  providedIn: 'root'
})
export class WizardService {

  constructor() { }
  setStep(steps: WizardSteps, gotoStep: number){
    //return steps.current = gotoStep; //uncomment to debug without constrains
    if (gotoStep < 0) return;
    const canSetStep = steps[gotoStep - 1 ?? 0]?.valid;
    const currentStep = steps[steps.current];
    currentStep.dirty = true;
    if (currentStep.valid && canSetStep || gotoStep < steps.current){
      if (gotoStep < steps.length){
        steps.current = gotoStep;
      } else {
        steps.end = true;
      }
    }
  }
}
