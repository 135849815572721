<div class="modal" *ngIf="isOpen == true" (click)="handleClickOutside()"></div>
<div class="modal-content" [class.open]="isOpen" [class.large]="isLarge" [style.--width]="width">
  <div class="popup-modal-header-container liquid-popup-header-container"
    [class.modal-title-bar]="!modalTitle">
    <p class="liquid-popup-header">{{modalTitle}}</p>
    <svg class="liquid-popup-close-icon" *ngIf="!alwaysOpen" (click)="handleClose()"
      width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M2.35355 2.64642L20.3535 20.6464" stroke="#99C6E4" stroke-width="4" stroke-linecap="round"/>
      <path d="M20.3536 2.64642L2.35357 20.6464" stroke="#99C6E4" stroke-width="4" stroke-linecap="round"/>
    </svg>
  </div>
  <div class="liquid-modal-container">
    <img *ngIf="isDanger" class="setup-completed" src="./assets/images/popup-rejected.png"/>
    <img *ngIf="isSuccess" class="setup-completed" src="./assets/images/setup-completed.png"/>
    <p class="liquid-popup-header modal-text-centered" [style.color]="isDanger ? '#FF6F6F' : 'inherit'">{{message}}</p>
    <ng-content></ng-content>
  </div>
  <div *ngIf="!noButtons" class="liquid-popup-buttons-container delete-buttons-container">
    <button class="liquid-primary-button modal-button liquid-modal-button complete-button" [disabled]="hasDisabledField == true && disabledConfirmButton == true" [class.delete-button]="isDanger" (click)="handleConfirm()">{{confirmLabel || 'Confirm'}}</button>
    <button *ngIf="!alwaysOpen" class="liquid-primary-button modal-button liquid-modal-button complete-button" [class.secondary-button]="isCloseSecondary" (click)="handleClose()">{{ closeLabel ? closeLabel : 'Close' }}</button>
  </div>
</div>
