import { EnumPermissionsActions } from '../actions/permissions.actions';
import { PermissionsActions } from '../actions/permissions.actions';
import { initialPermissionsState } from '../state/permissions.state';

export function permissionsReducer(
  state = initialPermissionsState,
  action: PermissionsActions
) {
  switch (action.type) {
    case EnumPermissionsActions.getPermissionsSuccess:
      return {
        ...state,
        permissions: action.payload
      };
    default:
      return state;
  }
}
