import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { InstructionService } from '../../services/instruction/instruction.service';
import { CompanyService } from '../../services/company/company.service';
import { NameValuePair } from 'types/common';
import { Rate } from 'types/instruction';
import { takeUntil } from 'rxjs/operators';
import { ReplaySubject } from 'rxjs';
import { deepClone } from 'src/app/app.module';

@Component({
  selector: 'app-currency-converter',
  templateUrl: './currency-converter.component.html',
  styleUrls: ['./currency-converter.component.scss']
})
export class CurrencyConverterComponent implements OnInit, OnDestroy {
  ready: boolean;
  error: boolean;

  baseCurrency: string;
  baseCountry: string;
  baseAmount: number;
  baseList: NameValuePair[];

  quotedCurrency: string;
  quotedCountry: string;
  quotedAmount: number;
  quotedList: NameValuePair[];

  private rates: Rate[];
  rate: number;

  private companyId: string;
  isMultiCurrency: boolean;
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  @Input() inline: boolean = false;
  @Input() readonly: boolean = false;
  @Input() withBackup: boolean = false;

  @Input() set SetBaseCurrency(value: string) {
    //console.log({base: value});
    this.setCurrency(value, true);
  }
  @Input() set SetQuotedCurrency(value: string) {
    //console.log({quoted: value});
    this.setCurrency(value, false);
  }
  
  @Output() onRates: EventEmitter<Rate[]> = new EventEmitter<Rate[]>();

  constructor(
    private instructionService: InstructionService,
    private companyService: CompanyService,
  ) { }

  ngOnInit(): void {
    const companyId = localStorage.getItem('liquid_payout_portal_companyId');
    const isMultiCurrency = localStorage.getItem('liquid_payout_portal_is_multicurrency') === 'true';
    this.getRates(companyId, isMultiCurrency);
    
    this.companyService.getSelectedCompany().pipe(takeUntil(this.destroyed$)).subscribe(company => {
      //console.log('Currency Converter => Company updated?', {company});
      if (this.companyId !== company.companyId){
        this.getRates(company.companyId, company.isMultiCurrency);
      }
    });
  }
  
  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  setCurrency(selectedCurrency: string, isBaseCurrency?: boolean){
    if (selectedCurrency === (isBaseCurrency ? this.baseCurrency : this.quotedCurrency)){
      return;
    }
    this.ready = false;
    if (isBaseCurrency){
      this.baseCurrency = selectedCurrency;
      this.baseCountry = this.setFlag(this.baseCurrency);
      this.setQuotedCurrencyList();
    } else {
      this.quotedCurrency = selectedCurrency;
      this.quotedCountry = this.setFlag(this.quotedCurrency);
    }
    this.setRate();
    this.ready = true;
    this.calc();
  }

  calc(){
    if (this.baseAmount){
      this.quotedAmount = Number((this.baseAmount * this.rate).toFixed(2));
    } else {
      this.quotedAmount = null;
    }
  }

  private getRates(companyId: string, isMultiCurrency: boolean){
    this.companyId = companyId;
    this.isMultiCurrency = isMultiCurrency;
    if (!this.isMultiCurrency) {
      this.error = true;
      return;
    }
    
    this.instructionService.getCurrencyRates(this.withBackup).subscribe(rates => {
      this.ready = false;
      this.rates = rates;
      if (!this.rates || this.rates.length === 0){
        this.error = true;
        return;
      }

      this.setBaseCurrencyList();
      this.setQuotedCurrencyList();
      this.setInverseRates();
      
      this.setRate();
      this.error = false;
      this.ready = true;
      this.calc();

      this.onRates.emit(this.rates);
    }, error => {
      this.error = true;
    });
  }

  private setBaseCurrencyList(){
    if (!this.rates)
      return;
    const rates = this.isInverse
      ? this.inverseRates
      : this.rates;
    this.baseList = rates.map(x => x.baseCurrency).toUnique().toNameValue();
    if (this.baseList.length > 0){
      this.baseCurrency = this.baseList[0].value;
    }
    this.baseCountry = this.setFlag(this.baseCurrency);
  }

  private setQuotedCurrencyList(){
    if (!this.rates)
      return;
    const rates = this.isInverse
      ? this.inverseRates
      : this.rates;
    this.quotedList = rates.filter(x => x.baseCurrency === this.baseCurrency)
      .map(x => x.quotedCurrency).toUnique().toNameValue();
    if (this.quotedList.length > 0 && !this.quotedList.find(x => x.value === this.quotedCurrency)){
      this.quotedCurrency = this.quotedList[0].value;
    }
    this.quotedCountry = this.setFlag(this.quotedCurrency);
  }

  private setInverseRates(){
    this.inverseRates = this.rates.map(x => {
      const inverse: Rate = {
        ...x,
        baseCurrency: x.quotedCurrency,
        quotedCurrency: x.baseCurrency,
        rate: x.rate !== 0 ? 1 / x.rate : 1
      };
      return inverse;
    });
  }

  @Input() withSwitch: boolean = false;
  private isInverse: boolean = false;
  private inverseRates: Rate[];
  switch(){
    const quotedCurrency = this.baseCurrency;
    const baseCurrency = this.quotedCurrency;

    const tempList = deepClone(this.baseList);
    this.baseList = deepClone(this.quotedList);
    this.quotedList = tempList;

    this.baseAmount = this.quotedAmount;
    this.baseCurrency = baseCurrency;
    this.quotedCurrency = quotedCurrency;
    this.isInverse = !this.isInverse;
    this.setCurrency(baseCurrency, true);
    this.setCurrency(quotedCurrency, false);
    this.baseCountry = this.setFlag(this.baseCurrency);
    this.quotedCountry = this.setFlag(this.quotedCurrency);
    this.setRate();
    this.calc();
  }

  private setRate(){
    if (!this.rates)
      return;
    const rates = this.isInverse
      ? this.inverseRates
      : this.rates;
    this.rate = this.instructionService.getPreferredRate(rates, this.baseCurrency, this.quotedCurrency);
  }

  private setFlag(currency: string){
    return this.countryCurrency[currency] || currency?.substring(0, 2) || 'SG'
  }

  private countryCurrency = {
    AUD: 'AU',
    CAD: 'CA',
    EUR: 'EU',
    GBP: 'GB',
    GHS: 'GH',
    HKD: 'HK',
    INR: 'IN',
    NZD: 'NZ',
    PHP: 'PH',
    SGD: 'SG',
    USD: 'US',
  };

}
