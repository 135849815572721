import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-remove-button, [remove-button]',
  templateUrl: './remove-button.component.html',
  styleUrls: ['./remove-button.component.scss'],
})
export class RemoveButtonComponent implements OnInit {

  @Input() label: string = "Remove";
  @Output() action: EventEmitter<void> = new EventEmitter<void>();

  constructor() { }
  ngOnInit(): void { }

  remove(event){
    event.preventDefault();
    event.stopPropagation();
    this.action.emit();
  }

}
