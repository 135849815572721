import { Action, createAction, props } from '@ngrx/store';

export enum EnumCompaniesActions {
  getCompanies = '[Company] get Companies',
  getCompaniesSuccess = '[Company] get Companies Success'
}

export class getCompanies implements Action {
  public readonly type = EnumCompaniesActions.getCompanies;
  constructor(public payload: any){}
}

export class getCompaniesSuccess implements Action {
  public readonly type = EnumCompaniesActions.getCompaniesSuccess;
  constructor(public payload: any){}
}

export type CompaniesActions = getCompanies | getCompaniesSuccess;
