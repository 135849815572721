import { Injectable } from '@angular/core';
import { catchError, map } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ValidationService } from '../validation/validation.service';
import { ConfigService } from '../config/config.service';
import { Observable, of } from 'rxjs';
import { MyInfoService } from '../my-info/my-info.service';

@Injectable({
  providedIn: 'root'
})
export class SelfOnboardingService {

  constructor(
    private http: HttpClient,
    private configService: ConfigService,
    private validationService: ValidationService,
    private myInfoService: MyInfoService,
  ) { }

  validateUen(uen){
    return this.validationService.validateUen(uen);
  }
  
  sendOTP(emailId: string){
    //return of((new Date()).getTime());
    return this.post(`/selfonboarding/v1/self-onboarding/emailverification/send`, {emailId});
  }
  
  confirmOTP(emailId: string, otp: string){
    return this.post(`/selfonboarding/v1/self-onboarding/verifyemailotp`, {emailId, otp}, 'text');
  }

  uploadFile(id: string, uen: string, file: File, field: string){
    const formData = new FormData();
    formData.append('id', id);
    formData.append('uen', uen);
    formData.append('file', file);
    formData.append('name', field);

    return this.post(`/selfOnboarding/upload`, formData);
  }
  
  save(body, key){
    return this.post(`/selfOnboarding/v1/self-onboarding/save`, body, null, key);
  }
  
  listFiles(uen, id, password){
    return this.post(`/selfOnboarding/getFiles`, {uen, id, password});
  }
  
  openFile(uen, id, password, name){
    return this.post(`/selfOnboarding/download`, {password, id, uen, name}, 'blob');
  }

  addCompany(emailId: string, partnerId: string){ 
    return this.post('/SelfOnboarding/v1/self-onboarding/onboard', {emailId, partnerId}, 'text');
  }
  
  retrieveInfo(code: string) {
    return this.post(`/selfonboarding/v1/self-onboarding/retrievedetails`, {authorisationCode: code});
  }

  getLeads(){
    const lead_redirects = {
      proxtera: this.configService.getProxteraUrl(),
    };
    return lead_redirects;
  }

  private getServiceRoot(){
    //return 'http://localhost:5001';
    return this.configService.getEndpointApiGateway();
  }

  private getHeader(): HttpHeaders {
    const headers = new HttpHeaders({
      //'authorization': 'bearer ' + localStorage.getItem('liquid_payout_portal_id_token'),
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': 'GET, POST, PATCH, PUT, DELETE, OPTIONS',
      'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token',
    });
    return headers;
  }
  
  private get(url: string){
    const headers = this.getHeader();

    return this.http.get(
      this.getServiceRoot() + '/partner/api' + url,
      { headers }
    ).pipe(
      map((response: any) => { return response }),
      catchError(err => { return err })
    );
  }
  
  private post(url: string, body?: any, responseType?: any, key?: string){
    let headers: HttpHeaders = this.getHeader();
    if (key)
      headers = headers.set('Liquid-Api-Key', key);

    return this.http.post(
      this.getServiceRoot() + '/partner/api' + url,
      body || null,
      { headers, responseType: responseType || 'json' }
    ).pipe(
      map((response: any) => { return response }),
      catchError(err => { throw err })
    );
  }

  getNationalityList(){
    return [
      {value: 'AD', name: 'ANDORRAN'},
      {value: 'AE', name: 'UNITED ARAB EMIRATES'},
      {value: 'AF', name: 'AFGHAN'},
      {value: 'AG', name: 'ANTIGUAN'},
      {value: 'AL', name: 'ALBANIAN'},
      {value: 'AM', name: 'ARMENIAN'},
      {value: 'AO', name: 'ANGOLAN'},
      {value: 'AR', name: 'ARGENTINIAN'},
      {value: 'AT', name: 'AUSTRIAN'},
      {value: 'AU', name: 'AUSTRALIAN'},
      {value: 'AZ', name: 'AZERBAIJANI'},
      {value: 'BA', name: 'BOSNIAN'},
      {value: 'BB', name: 'BARBADOS'},
      {value: 'BD', name: 'BANGLADESHI'},
      {value: 'BE', name: 'BELGIAN'},
      {value: 'BF', name: 'BURKINABE'},
      {value: 'BG', name: 'BULGARIAN'},
      {value: 'BH', name: 'BAHRAINI'},
      {value: 'BI', name: 'BURUNDIAN'},
      {value: 'BJ', name: 'BENINESE'},
      {value: 'BL', name: 'BELARUSSIAN'},
      {value: 'BN', name: 'BRUNEIAN'},
      {value: 'BO', name: 'BOLIVIAN'},
      {value: 'BR', name: 'BRAZILIAN'},
      {value: 'BS', name: 'BAHAMIAN'},
      {value: 'BT', name: 'BHUTANESE'},
      {value: 'BU', name: 'MYANMAR'},
      {value: 'BW', name: 'BOTSWANA'},
      {value: 'BZ', name: 'BELIZEAN'},
      {value: 'CA', name: 'CANADIAN'},
      {value: 'CB', name: 'CROATIAN'},
      {value: 'CD', name: 'DEMOCRATIC REPUBLIC OF THE CONGO'},
      {value: 'CF', name: 'CENTRAL AFRICAN REPUBLIC'},
      {value: 'CG', name: 'CONGOLESE'},
      {value: 'CH', name: 'SWISS'},
      {value: 'CI', name: 'IVORY COAST'},
      {value: 'CL', name: 'CHILEAN'},
      {value: 'CM', name: 'CAMEROONIAN'},
      {value: 'CN', name: 'CHINESE'},
      {value: 'CO', name: 'COLOMBIAN'},
      {value: 'CR', name: 'COSTA RICAN'},
      {value: 'CU', name: 'CUBAN'},
      {value: 'CV', name: 'CAPE VERDEAN'},
      {value: 'CY', name: 'CYPRIOT'},
      {value: 'CZ', name: 'CZECH'},
      {value: 'DG', name: 'GERMAN'},
      {value: 'DJ', name: 'DJIBOUTIAN'},
      {value: 'DK', name: 'DANISH'},
      {value: 'DM', name: 'DOMINICAN'},
      {value: 'DO', name: 'DOMINICAN (REPUBLIC)'},
      {value: 'DZ', name: 'ALGERIAN'},
      {value: 'EC', name: 'ECUADORIAN'},
      {value: 'EG', name: 'EGYPTIAN'},
      {value: 'EN', name: 'ESTONIAN'},
      {value: 'ER', name: 'ERITREAN'},
      {value: 'ES', name: 'SPANISH'},
      {value: 'ET', name: 'ETHIOPIAN'},
      {value: 'FI', name: 'FINNISH'},
      {value: 'FJ', name: 'FIJIAN'},
      {value: 'FR', name: 'FRENCH'},
      {value: 'GA', name: 'GABON'},
      {value: 'GB', name: 'BRITISH'},
      {value: 'GC', name: 'BRITISH OVERSEAS TERRITORIES CITIZEN'},
      {value: 'GD', name: 'GRENADIAN'},
      {value: 'GE', name: 'BRITISH OVERSEAS CITIZEN'},
      {value: 'GG', name: 'BRITISH NATIONAL OVERSEAS'},
      {value: 'GH', name: 'GHANAIAN'},
      {value: 'GJ', name: 'BRITISH PROTECTED PERSON'},
      {value: 'GM', name: 'GAMBIAN'},
      {value: 'GN', name: 'GUINEAN'},
      {value: 'GO', name: 'GEORGIAN'},
      {value: 'GQ', name: 'EQUATORIAL GUINEA'},
      {value: 'GR', name: 'GREEK'},
      {value: 'GT', name: 'GUATEMALAN'},
      {value: 'GW', name: 'GUINEAN (BISSAU)'},
      {value: 'GY', name: 'GUYANESE'},
      {value: 'HK', name: 'HONG KONG'},
      {value: 'HN', name: 'HONDURAN'},
      {value: 'HT', name: 'HAITIAN'},
      {value: 'HU', name: 'HUNGARIAN'},
      {value: 'ID', name: 'INDONESIAN'},
      {value: 'IE', name: 'IRISH'},
      {value: 'IL', name: 'ISRAELI'},
      {value: 'IN', name: 'INDIAN'},
      {value: 'IQ', name: 'IRAQI'},
      {value: 'IR', name: 'IRANIAN'},
      {value: 'IS', name: 'ICELANDER'},
      {value: 'IT', name: 'ITALIAN'},
      {value: 'JM', name: 'JAMAICAN'},
      {value: 'JO', name: 'JORDANIAN'},
      {value: 'JP', name: 'JAPANESE'},
      {value: 'KA', name: 'CAMBODIAN'},
      {value: 'KE', name: 'KENYAN'},
      {value: 'KI', name: 'KIRIBATI'},
      {value: 'KM', name: 'COMORAN'},
      {value: 'KP', name: 'KOREAN, NORTH'},
      {value: 'KR', name: 'KOREAN, SOUTH'},
      {value: 'KW', name: 'KUWAITI'},
      {value: 'KY', name: 'KYRGYZSTAN'},
      {value: 'KZ', name: 'KAZAKHSTANI'},
      {value: 'LA', name: 'LAOTIAN'},
      {value: 'LB', name: 'LEBANESE'},
      {value: 'LC', name: 'ST. LUCIA'},
      {value: 'LH', name: 'LITHUANIAN'},
      {value: 'LI', name: 'LIECHTENSTEINER'},
      {value: 'LK', name: 'SRI LANKAN'},
      {value: 'LR', name: 'LIBERIAN'},
      {value: 'LS', name: 'LESOTHO'},
      {value: 'LU', name: 'LUXEMBOURGER'},
      {value: 'LV', name: 'LATVIAN'},
      {value: 'LY', name: 'LIBYAN'},
      {value: 'MA', name: 'MOROCCAN'},
      {value: 'MB', name: 'MACEDONIAN'},
      {value: 'MC', name: 'MONACAN'},
      {value: 'MD', name: 'MOLDAVIAN'},
      {value: 'MF', name: 'MICRONESIAN'},
      {value: 'MG', name: 'MADAGASY'},
      {value: 'MH', name: 'MARSHALLESE'},
      {value: 'MJ', name: 'MONTENEGRIN'},
      {value: 'ML', name: 'MALIAN'},
      {value: 'MN', name: 'MONGOLIAN'},
      {value: 'MO', name: 'MACAO'},
      {value: 'MR', name: 'MAURITANEAN'},
      {value: 'MT', name: 'MALTESE'},
      {value: 'MU', name: 'MAURITIAN'},
      {value: 'MV', name: 'MALDIVIAN'},
      {value: 'MW', name: 'MALAWIAN'},
      {value: 'MX', name: 'MEXICAN'},
      {value: 'MY', name: 'MALAYSIAN'},
      {value: 'MZ', name: 'MOZAMBICAN'},
      {value: 'NA', name: 'NAMIBIA'},
      {value: 'NE', name: 'NIGER'},
      {value: 'NG', name: 'NIGERIAN'},
      {value: 'NI', name: 'NICARAGUAN'},
      {value: 'NO', name: 'NORWEGIAN'},
      {value: 'NP', name: 'NEPALESE'},
      {value: 'NR', name: 'NAURUAN'},
      {value: 'NT', name: 'NETHERLANDS'},
      {value: 'NZ', name: 'NEW ZEALANDER'},
      {value: 'OM', name: 'OMANI'},
      {value: 'PA', name: 'PANAMANIAN'},
      {value: 'PE', name: 'PERUVIAN'},
      {value: 'PG', name: 'PAPUA NEW GUINEAN'},
      {value: 'PH', name: 'FILIPINO'},
      {value: 'PK', name: 'PAKISTANI'},
      {value: 'PL', name: 'POLISH'},
      {value: 'PN', name: 'PALESTINIAN'},
      {value: 'PT', name: 'PORTUGUESE'},
      {value: 'PW', name: 'PALAUAN'},
      {value: 'PY', name: 'PARAGUAYAN'},
      {value: 'QA', name: 'QATARI'},
      {value: 'RF', name: 'RUSSIAN'},
      {value: 'RO', name: 'ROMANIAN'},
      {value: 'RS', name: 'SERBIAN'},
      {value: 'RW', name: 'RWANDAN'},
      {value: 'SA', name: 'SAUDI ARABIAN'},
      {value: 'SB', name: 'SOLOMON ISLANDER'},
      {value: 'SC', name: 'SEYCHELLOIS'},
      {value: 'SD', name: 'SUDANESE'},
      {value: 'SE', name: 'SWEDISH'},
      {value: 'SG', name: 'SINGAPORE CITIZEN'},
      {value: 'SI', name: 'SLOVENIAN'},
      {value: 'SK', name: 'SLOVAK'},
      {value: 'SL', name: 'SIERRA LEONE'},
      {value: 'SM', name: 'SAMMARINESE'},
      {value: 'SN', name: 'SENEGALESE'},
      {value: 'SO', name: 'SOMALI'},
      {value: 'SP', name: 'SINGAPORE PR'},
      {value: 'SR', name: 'SURINAMER'},
      {value: 'SS', name: 'STATELESS'},
      {value: 'ST', name: 'SAO TOMEAN'},
      {value: 'SV', name: 'SALVADORAN'},
      {value: 'SW', name: 'KITTIAN & NEVISIAN'},
      {value: 'SY', name: 'SYRIAN'},
      {value: 'SZ', name: 'SWAZI'},
      {value: 'TD', name: 'CHADIAN'},
      {value: 'TG', name: 'TOGOLESE'},
      {value: 'TH', name: 'THAI'},
      {value: 'TI', name: 'TAJIKISTANI'},
      {value: 'TM', name: 'TURKMEN'},
      {value: 'TN', name: 'TUNISIAN'},
      {value: 'TO', name: 'TONGAN'},
      {value: 'TP', name: 'EAST TIMORESE'},
      {value: 'TR', name: 'TURK'},
      {value: 'TT', name: 'TRINIDADIAN & TOBAGONIAN'},
      {value: 'TV', name: 'TUVALU'},
      {value: 'TW', name: 'TAIWANESE'},
      {value: 'TZ', name: 'TANZANIAN'},
      {value: 'UG', name: 'UGANDAN'},
      {value: 'UK', name: 'BRITISH SUBJECT'},
      {value: 'UN', name: 'UNKNOWN'},
      {value: 'UR', name: 'UKRAINIAN'},
      {value: 'US', name: 'AMERICAN'},
      {value: 'UY', name: 'URUGUAYAN'},
      {value: 'UZ', name: 'UZBEKISTAN'},
      {value: 'VA', name: 'VATICAN CITY STATE (HOLY SEE)'},
      {value: 'VC', name: 'ST. VINCENTIAN'},
      {value: 'VE', name: 'VENEZUELAN'},
      {value: 'VN', name: 'VIETNAMESE'},
      {value: 'VU', name: 'NI-VANUATU'},
      {value: 'WS', name: 'SAMOAN'},
      {value: 'XB', name: 'REFUGEE (XXB)'},
      {value: 'XC', name: 'REFUGEE (OTHER THAN XXB)'},
      {value: 'YM', name: 'YEMENI'},
      {value: 'ZA', name: 'SOUTH AFRICAN'},
      {value: 'ZM', name: 'ZAMBIAN'},
      {value: 'ZW', name: 'ZIMBABWEAN'},
    ]
  }
}
