import { Injectable } from '@angular/core';
import { ApiService } from '../api/api.service';
import { OperationsService } from '../operations/operations.service';
import { switchMap, map, share, catchError } from 'rxjs/operators';
import { Observable, of, EMPTY, Subject, BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';
import { HttpClient, HttpResponse, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { NotifierService } from 'angular-notifier';
import { ConfigService } from '../config/config.service';
import * as FileSaver from 'file-saver';

export type Partner = {
  partnerId: string,
  name: string,
  countryCode: string,
  address: string,
  notificationEndpoint: string,
  apiKey: string,
  notificationApiKey: string,
  isInvestor: boolean,//missing in endpoint
};

@Injectable({
  providedIn: 'any'
})
export class PartnerService {

  constructor(
    private apiService: ApiService,
    private router: Router,
    private http: HttpClient,
    private notifierService: NotifierService,
    private operationsService: OperationsService,
    private configService: ConfigService
  ) {}

  getSuppliers(partnerId: string){
    return this.apiService.get(`/partners/Suppliers?CompanyId=${localStorage.getItem('liquid_payout_portal_companyId')}&PartnerId=${localStorage.getItem('liquid_payout_portal_partnerId')}`).pipe(switchMap((response: any) => {
      return of(response);
    }))
  }

  updateSupplier(partnerId: string, supplier, supplierId){
    return this.apiService.update(`/partners/${partnerId}/suppliers/${supplierId}`, supplier).pipe(switchMap((response: any) => {
      return of(response);
    }))
  }

  deleteSupplier(partnerId: string, supplierId){
    return this.apiService.delete(`/partners/${partnerId}/suppliers/${supplierId}`).pipe(switchMap((response: any) => {
      return of(response);
    }))
  }

  createSupplier(partnerId, supplier){
    return this.apiService.post(`/partners/${partnerId}/suppliers`, supplier).pipe(switchMap((response: any) => {
      this.notifierService.notify('success','Supplier account successfully added');
      return of(response);
    }))
  }

  public static Partners: Partner[];
  getPartners(){
    if (PartnerService.Partners) {
      //console.log('🗃️ cached partners', PartnerService.Partners);
      return of({data: PartnerService.Partners, totalItems: PartnerService.Partners.length});
    }
    
    return this.apiService.get('/partners?Page=1&PageSize=100&IsCompanyAssociatedOnly=true').pipe(switchMap((response: {data: Partner[], totalItems: number}) => {
      PartnerService.Partners = response.data;
      //console.log('🗄️ load partners', PartnerService.Partners);
      return of(response);
    }))
  }

  getDashboardConfig(){
    return this.apiService.get('/setting/user-quick-link-configuration').pipe(switchMap((response: any) => {
      return of(response);
    }))
  }

  updateDashboardConfig(config){
    return this.apiService.post('/setting/user-quick-link-configuration', config).pipe(switchMap((response: any) => {
      return of(response);
    }))
  }

  getOnboardingEnquiryList(queryString: string): Observable<any> {

    let headers = new HttpHeaders({
      authorization: 'bearer ' + localStorage.getItem('liquid_payout_portal_id_token')
    });
    headers = headers.append('Access-Control-Allow-Origin', '*');
    headers = headers.append('Access-Control-Allow-Methods', 'GET, POST, PATCH, PUT, DELETE, OPTIONS');
    headers = headers.append('Access-Control-Allow-Headers', 'Origin, Content-Type, X-Auth-Token');
    return this.http.get(this.configService.getEndpointApiGateway() + '/partner/api/SelfOnboarding/v1/self-onboarding' + queryString, {headers: headers}).pipe(map((response: any) => {
      return response;
    }), catchError(err => {
      console.error('An error occurred:', err.status);
      return err;
    }));
  }

  getAuditTrail(applicationId: string): Observable<any> {

    let headers = new HttpHeaders({
      authorization: 'bearer ' + localStorage.getItem('liquid_payout_portal_id_token')
    });
    headers = headers.append('Access-Control-Allow-Origin', '*');
    headers = headers.append('Access-Control-Allow-Methods', 'GET, POST, PATCH, PUT, DELETE, OPTIONS');
    headers = headers.append('Access-Control-Allow-Headers', 'Origin, Content-Type, X-Auth-Token');

    let body = {
      applicationId: applicationId
    }

    return this.http.post(this.configService.getEndpointApiGateway() + '/partner/api/SelfOnboarding/v1/self-onboarding/audit-tracking', body, {headers: headers}).pipe(map((response: any) => {
      return response;
    }), catchError(err => {
      this.validateError(err);
      console.error('An error occurred:', err.status);
      return err;
    }));
  }

  changeApplicationStatus(applicationId: string, applicationStatus: string): Observable<any> {

    let headers = new HttpHeaders({
      authorization: 'bearer ' + localStorage.getItem('liquid_payout_portal_id_token')
    });
    headers = headers.append('Access-Control-Allow-Origin', '*');
    headers = headers.append('Access-Control-Allow-Methods', 'GET, POST, PATCH, PUT, DELETE, OPTIONS');
    headers = headers.append('Access-Control-Allow-Headers', 'Origin, Content-Type, X-Auth-Token');

    let body = {
      status: applicationStatus
    }

    return this.http.post(this.configService.getEndpointApiGateway() + `/partner/api/SelfOnboarding/v1/self-onboarding/${applicationId}/update-status`, body, {headers: headers}).pipe(map((response: any) => {
      return response;
    }), catchError(err => {
      this.validateError(err);
      console.error('An error occurred:', err.status);
      return err;
    }));
  }

  getApplicationDetails(applicationId: string): Observable<any> {

    let headers = new HttpHeaders({
      authorization: 'bearer ' + localStorage.getItem('liquid_payout_portal_id_token')
    });
    headers = headers.append('Access-Control-Allow-Origin', '*');
    headers = headers.append('Access-Control-Allow-Methods', 'GET, POST, PATCH, PUT, DELETE, OPTIONS');
    headers = headers.append('Access-Control-Allow-Headers', 'Origin, Content-Type, X-Auth-Token');
    headers = headers.append('accept', 'text/plain');

    let body = {
      applicationId: applicationId
    }

    return this.http.post(this.configService.getEndpointApiGateway() + '/partner/api/SelfOnboarding/v1/self-onboarding/' + applicationId, body, {headers: headers}).pipe(map((response: any) => {
      return response;
    }), catchError(err => {
      console.error('An error occurred:', err.status);
      this.validateError(err);
      return err;
    }));
  }

  uploadFile(applicationId: string, name: string){
    let headers = new HttpHeaders({
      authorization: 'bearer ' + localStorage.getItem('liquid_payout_portal_id_token')
    });
    headers = headers.append('Access-Control-Allow-Origin', '*');
    headers = headers.append('Access-Control-Allow-Methods', 'GET, POST, PATCH, PUT, DELETE, OPTIONS');
    headers = headers.append('Access-Control-Allow-Headers', 'Origin, Content-Type, X-Auth-Token');

    let body = {
      fileNameList: ['all']
    }

    return this.http.post(this.configService.getEndpointApiGateway() + `/partner/api/SelfOnboarding/v1/self-onboarding/${applicationId}/get-files`, body, {responseType: 'arraybuffer', headers: headers}).pipe(switchMap((response: any) => {
      const b64toBlob = (b64Data, contentType) => {
        const byteCharacters = atob(b64Data);
        var sliceSize = 512;
        const byteArrays = [];

        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
          const slice = byteCharacters.slice(offset, offset + sliceSize);

          const byteNumbers = new Array(slice.length);
          for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
          }

          const byteArray = new Uint8Array(byteNumbers);
          byteArrays.push(byteArray);
        }

        const blob = new Blob(byteArrays, {type: contentType});
        return blob;
      }

     console.log('Response', response);

     var enc = new TextDecoder("utf-8");
     console.log(enc.decode(response));

     var result = enc.decode(response);
     let convertedString = JSON.parse(result).zipFileByteArray;

     if (convertedString != null){
       var blob = b64toBlob(convertedString, 'application/zip');
       FileSaver.saveAs(blob, name + '.zip');
     } else {
       this.notifierService.notify('error', 'Cannot find any files for this application');
     }
      return of(EMPTY);
    }))
  }


  getAllPartners(){
    return this.apiService.get('/partners?Page=1&PageSize=100&IsCompanyAssociatedOnly=false').pipe(switchMap((response: any) => {
      return of(response);
    }))
  }

  validateError(error) {
    if (error.status == 401 || error.status == 0 || error.status == 403) {
      if (!localStorage.getItem('liquid_payout_portal_current_route')){
        localStorage.setItem('liquid_payout_portal_current_route', this.router.url);
      }
      PartnerService.Partners = null;
      this.router.navigateByUrl('/login');
    } else {
      console.log(error);
      if (error.error.ResponseStatus.Description && error.status != 403){
      } else if (!error.error.ResponseStatus.Description) {
      } else {

      }
    }
    return EMPTY;
  }
}
