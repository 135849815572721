<section *ngFor="let section of _sections">
  <header>
    <img [src]="'./assets/icons/' + section.icon" [alt]="section.title">
    {{section.title}}
  </header>
  <nav>
    <ul>
      <li *ngFor="let item of section.items">
        <a [routerLink]="item.link">
          <p class="title">{{item.title}}</p>
          <p class="description">{{item.description}}</p>
        </a>
      </li>
    </ul>
  </nav>
</section>