import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType, Effect } from '@ngrx/effects';
import { of, EMPTY } from 'rxjs';
import { map, exhaustMap, catchError, switchMap, concatMap } from 'rxjs/operators';
import { InstructionService } from '../../shared/services/instruction/instruction.service';
import { PartnerService } from '../../shared/services/partner/partner.service';
import { EnumPartnersActions, getPartnersSuccess, getPartners } from '../actions/partners.actions';
// import * as notificationsActions from '../actions';
import * as moment from "moment";
import 'moment-timezone';

@Injectable()
export class PartnersEffects {
    timeZone: number;

    @Effect() getPartners$ =
    this.actions$.pipe(
      ofType<getPartners>(EnumPartnersActions.getPartners),
      switchMap((action) => this.partnerService.getPartners()),
      switchMap((eventHttp: any) => of(new getPartnersSuccess(eventHttp)))
    );

  constructor(
    private instructionService: InstructionService,
    private partnerService: PartnerService,
    private actions$: Actions
  ) {

  }
}
