import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MyInfoService } from 'src/app/shared/services/my-info/my-info.service';

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss']
})
export class SignUpComponent implements OnInit {

  constructor(
    private service: MyInfoService,
    private route: ActivatedRoute,
    ) { }
  partner: string = this.route.snapshot.queryParams.partner || this.route.snapshot.queryParams.id;
  lead: string = this.route.snapshot.queryParams.lead;
  year: number = new Date().getFullYear();
  ngOnInit(): void { }

  myInfoLogin(){
    const url = this.service.getMyInfoBizURL(this.partner || 'new', this.lead);
    console.log('✔ Redirecting to MyInfo...', url);
    window.location.href = url;
  }  
}
