import { Action, createAction, props } from '@ngrx/store';

export enum EnumPartnersActions {
  getPartners = '[Partner] get Partners',
  getPartnersSuccess = '[Partner] get Partners Success'
}

export class getPartners implements Action {
  public readonly type = EnumPartnersActions.getPartners;
  constructor(public payload: any){}
}

export class getPartnersSuccess implements Action {
  public readonly type = EnumPartnersActions.getPartnersSuccess;
  constructor(public payload: any){}
}

export type PartnersActions = getPartners | getPartnersSuccess;
